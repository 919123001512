import React,  {useRef} from 'react';
import Image from 'react-bootstrap/Image';
import Title from '../resources/APPtitle.png';
import Guard from '../resources/Guardas/guarda2.png';
import LeftContainer from './utils/leftContainer.component';
import {Row, Col} from 'reactstrap';
import IconButton from './iconButton.component';
import {Red, Yellow} from '../constants';
import LogoIMG from '../resources/meplogo.png';
import CC from '../resources/Creditos/BYNCSA.png';
import ProdMontana from '../resources/Creditos/ProdMontana.png';
import FontSizeModal from './fontSizeModal.component';
import "./credits.css";

export default function Credits(props){
    const fontSizeModal = useRef();

    const back = () => {
		props.history.push('/additionalResources',{ showInfo:false });
	}

    return(
        <div role="application" aria-label="Credits Screen.">
            <div className="right"><Image className='rightlogo2' src={Guard} aria-hidden="true"/></div>
            <LeftContainer>
                <Row className="middle middle-full credits-container">
                    <section tabIndex="0" role="document" className="credits-masked-section">
                        <h1 id="creditsTitle" className="screen-reader-text"> Credits. </h1>
                        <div className="credits">
                            <img className='' alt="MEP Logo." style={{height: "10vh", width: "15.158vh"}} src={LogoIMG} />
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <div className="credits-title"><p>Agradecemos a las siguientes instituciones</p></div>
                            <p>Museo de Arte Costarricense</p>
                            <p>Museo Nacional de Costa Rica</p>
                            <p>Museo del Jade</p>
                            <p>Museo de Arte y Diseño Contemporáneo</p>
                            <br aria-hidden="true"/>
                            <div className="credits-title"><p>Un agradecimiento especial a</p></div>
                            <p>Sr. Byron González Aguilar, <span className="credits-text-em">Museo de Arte Costarricense</span></p>
                            <p>Sra. Marlin Calvo Mora, <span className="credits-text-em">Museo Nacional de Costa Rica</span></p>
                            <p>Sr. José Alexis Matamoros, <span className="credits-text-em">Museo Nacional de Costa Rica</span></p>
                            <p>Sr. Sergio García Piedra, <span className="credits-text-em">Museo del Jade</span></p>
                            <p>Sr. Daniel Soto Morúa,  <span className="credits-text-em">Museo de Arte y Diseño Contemporáneo</span></p>
                            <p>Sr. Osvaldo López,  <span className="credits-text-em">Museo de Arte y Diseño Contemporáneo</span></p>
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <div className="credits-supertitle">
                                <div className="credits-ornament"></div>
                                <p>Dirección de Recursos Tecnológicos en Educación</p>
                            </div>
                            <div className="credits-title"><p>Departamento de Gestión y Producción de Recursos</p></div>
                            <div className="credits-subtitle"><p>Administradora del Proyecto</p></div>
                            <p>Rosa Elena Chacón Coto, <span className="credits-text-em">Jefa de Departamento</span> </p>
                            <br aria-hidden="true"/>
                            <div className="credits-subtitle"><p>Idea original, Conceptualización del Recurso, Producción del contenido, Supervisión y Coordinación del Desarrollo</p></div>
                            <p>Sirlene Chaves Vargas, <span className="credits-text-em">Asesora Nacional de Artes Plásticas</span></p>
                            <br aria-hidden="true"/>
                            <div className="credits-supertitle">
                                <div className="credits-ornament"></div>
                                <p>Dirección de Desarrollo Curricular</p>
                            </div>
                            <div className="credits-title"><p>Departamento de Tercer Ciclo y Educación Diversificada</p></div>
                            <div className="credits-subtitle"><p>Producción del contenido, Mediación Pedagógica, Supervisión del Desarrollo</p></div>
                            <p>Carlos Bermúdez Vives, <span className="credits-text-em">Asesor Nacional de Artes Plásticas</span></p>                           
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <div className="credits-title"><p>Desarrolladores</p></div>
                            <p>Paúl Fernández Barrantes</p>
                            <p>Adrian Fernández Malavasi</p>
                            <p>David Ramírez Guerrero</p>
                            <p>Jenny Vásquez Calderón</p>
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <div className="credits-title"><p>Diseño Gráfico</p></div>
                            <p>Josué González Gómez</p>
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <div className="credits-title"><p>Locución</p></div>
                            <p>Ingrid Chaves Mata</p>
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <div className="credits-title"><p>Modelado 3D</p></div>
                            <p>Sebastián Barrantes Chaves</p>
                            <p>Cesar Espinoza Pereira</p>
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <p>Una realización de Producciones de la Montaña</p>
                            <img className='' alt="Producciones de la montaña. Logo." style={{height: "15vh", width: "15.3813vh"}} src={ProdMontana} />
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <img className='' alt="Creative Commons Licence. Logo." style={{height: "5vh", width: "14.29vh"}} src={CC} />
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <p className="credits-text-small">Costa Rica, 2021</p>
                        </div>
                        <div className="credits-outro">
                            <img className='' alt="MEP Logo." style={{height: "10vh", width: "15.158vh"}} src={LogoIMG} />
                            <br aria-hidden="true"/><br aria-hidden="true"/>
                            <img className='' alt="MEP Logo." style={{height: "10vh", width: "auto"}} src={Title} />
                        </div>
                    </section>        
                </Row>
                <Row className="bottom-section">
                    <Col className="flex-begin"> <IconButton className='svgbtn back-svg' color={Red} function={()=>{back();}} popover="Atrás"/> </Col>
                    <Col className="flex-end"> 
                        <IconButton className='svgbtn fontSize-svg' color={Yellow} function={()=>{fontSizeModal.current.open()}} popover="Cambiar tamaño de letra" /> 
                    </Col>
                </Row>
            </LeftContainer>
            <FontSizeModal ref={fontSizeModal}/>	
        </div>
    );
}