import React, { useEffect } from 'react';
import {Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import {Loader} from './components/utils/loader.component'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import {store,persistor} from './store';
import Viewer from './components/viewer/viewer.component';
import Scanner from './components/scanner.component';
import Menu from './components/menu.component';
import MenuAR from './components/menu-ar.component';
import Art from './components/art.component';
import Questions from './components/questions.component';
import Introduction from './components/introduction.component';
import AdditionalResources from './components/additionalResources.component';
import Home from './components/home.component';
import Credits from './components/credits.component';
import { isPlatform } from '@ionic/react';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility'

function App() {
  MobileAccessibility.usePreferredTextZoom(false);

  useEffect(()=>{
    if(isPlatform("capacitor")) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
    }    
  }, []);
  
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader id="Store"/>} persistor={persistor}>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/intro" component={Introduction}/>
            <Route path="/menu" component={isPlatform('capacitor')?MenuAR:Menu}/>
            <Route exact path="/forcedMenu" component={Menu}/>
            <Route path="/art" component={Art}/>
            <Route path="/viewer" component={Viewer}/>
            <Route path="/subviewer" component={Viewer}/>
            <Route path="/scanner" component={Scanner}/>
            <Route path="/questions" component={Questions}/>
            <Route path="/additionalResources" component={AdditionalResources}/>
            <Route path="/credits"  component={Credits}/>
            <Route component={Error} /> 
          </Switch>
        </div>
      </PersistGate>
    </Provider>
  );
  
}
  
function Error(props) {
  return (
    <div className="container" style={{color: '#ffffff'}}>
      <h1>404 Page Not Found</h1>
    </div>
  )
}
  
export default App;