import React, { useEffect, useState } from 'react';
import './home.css';
import Title from '../resources/APPtitle.png';
import Mep from '../resources/meplogo.png';
import Image from 'react-bootstrap/Image';
import IconButton from './iconButton.component';
import {Yellow} from '../constants';
import { isPlatform } from '@ionic/react';
import { File } from '@ionic-native/file';
import ProgressRing from './utils/progressRing.component'
import axios from 'axios';
import { Zip } from '@ionic-native/zip';
import { ART_TYPES } from '../constants';
import { Insomnia } from '@ionic-native/insomnia';

export default function Home (props) {
	const [progress, setProgress] = useState(0);
	const [message, setMessage] = useState("");
	const [done, setDone] = useState(false);

	useEffect(() => {
		if(isPlatform('capacitor') && !isPlatform('ios')){
			setMessage("Verificando contenido...");
			checkFiles();
		} else {
			setDone(true);
		}
	}, []);

	const checkFiles = async () => { 
		let foundNonExistent = false;
		const artkeys = Object.keys(ART_TYPES);
		for(let i = 0; i < artkeys.length && !foundNonExistent; i++){
			const models = ART_TYPES[artkeys[i]]["models"];
			for(let j = 0; j < models.length; j++){
				setProgress(Math.round((i * models.length + j + 1) * 100 / (models.length * artkeys.length)));
				if(!models[j]){ continue; }

				let sourcePath = File.dataDirectory;
				sourcePath = sourcePath.endsWith('/') ? sourcePath : sourcePath + "/";
				sourcePath += "models/"+artkeys[i]+"/";

				const exists = await File.checkFile(sourcePath, models[j]+"_gltfpack.glb").catch((err) => { return false; });		
				if(!exists){
					foundNonExistent = true;
					break;
				}		
			}
		}

		if(foundNonExistent){
			Insomnia.keepAwake().then(() => console.log('success keeping awake'), () => console.log('error keeping awake'));
			setMessage("Descargando contenido...");		
			setProgress(0);
			downloadModels();
		}else {
			setMessage("");
			setDone(true);
		}
	}

	const downloadModels = () => {
		axios({
			url: 'https://mep-ciencias.s3.us-east-2.amazonaws.com/models.zip', 
			method: 'GET',
			responseType: 'blob',
			onDownloadProgress: (progressEvent) => {
				setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			}, 
		}).then((response) => {
			setMessage("Copiando contenido...");
			setProgress(0);
			return File.resolveDirectoryUrl(File.cacheDirectory).then((directoryEntry) => {
				File.getFile(directoryEntry, "models.zip", { create: true, exclusive: false }).then((fileEntry) => {
					fileEntry.createWriter((fileWriter) => {
						var data = new Blob([response.data], {type: 'application/zip'});
						var written = 0;
						var BLOCK_SIZE = 10*1024*1024; // 10MB
						var writeNext = (cbFinish) => {
							fileWriter.onwrite = function(evt) {
								setProgress(Math.round((written * 100) / data.size));
								if (written < data.size)
									writeNext(cbFinish);
								else
									cbFinish();
							};
							
							if (written) fileWriter.seek(fileWriter.length);
							fileWriter.write(data.slice(written, written + Math.min(BLOCK_SIZE, data.size - written)));
							written += Math.min(BLOCK_SIZE, data.size - written);
						}

						writeNext(() => {
							setMessage("Extrayendo contenido...");
							var sourcePath = File.cacheDirectory;
							sourcePath = sourcePath.endsWith('/') ? sourcePath : sourcePath + "/";

							Zip.unzip(sourcePath+'models.zip', File.dataDirectory, (progress) => {
								setProgress(Math.round((progress.loaded / progress.total) * 100));
							}).then((result) => {
								if(result === 0){
									File.removeFile(File.cacheDirectory, "models.zip")
									.then(() => {console.log("Caché limpia.");})
									.catch((err) => { console.log("No se pudo eliminar zip de descarga. Vaciar caché manualmente."); });
									
									Insomnia.allowSleepAgain().then(() => console.log('success sleeping'), () => console.log('error sleeping'));
									setMessage("Presiona la flecha para avanzar");
									setDone(true);
								}
								if(result === -1) alert("Error extrayendo los archivos, puede que necesites más espacio libre en el dispositivo.");
							}); 
						});

					}, (err) => { 
						alert("Error al cargar datos de la aplicación. Revisa tengas al menos 500mb de espacio libre en el dispositivo.");
						console.log(err); 
					});
				});
			}).catch((err) => {
				alert("Error al cargar datos de la aplicación. Revisa tengas al menos 500mb de espacio libre en el dispositivo.");
				console.log(err);
			});
		}, (err) => { 
			alert("Error al descargar datos de la aplicación. Cierra y abrela de nuevo.");
			console.log(err); 
		});
	}

    return(
		<div role="application" aria-labelledby="cac" aria-describedby="mep" className="vertical-center-max horizontal-center">
			<Image id="cac" style={{height:'50vh', maxWidth:'80vw'}} src={Title} alt="Las claves del arte costarricense."/>
			<Image id="mep" src={Mep} className="loading-MEP" alt="Ministerio de educación pública."/>		
			{ done ?
				<IconButton className='next-svg loading-NEXT' color={Yellow} function={()=>{props.history.push('/intro')}} popover="Avanzar"/>
				:
				<>
				<ProgressRing radius={60} stroke={10} progress={progress}/>
				<div tabIndex="0" className="progress-message" aria-describedby="progress-message" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"> <p id="progress-message"> {message} </p> </div>
				</>
			}
		</div>
    );
}