import React, {Component} from 'react';

import Phrase from './phrase.component';
import LeftContainer from './utils/leftContainer.component';
import Guard from '../resources/Guardas/guarda2.png';
import Image from 'react-bootstrap/Image';

class Introduction extends Component {

    goToNextScene(){
        this.props.history.push('/questions');
    }

    render() {
        
        return(
            <>
                <div className="right"><Image className='rightlogo2' src={Guard} aria-hidden="true"/></div>
                <LeftContainer>
                    <Phrase onEnd={this.goToNextScene.bind(this)}></Phrase>
                </LeftContainer>
            </>   
        )
	}
}

export default (Introduction);