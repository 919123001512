import React, { useEffect,useState} from 'react';
import ColorPicker from '../color-picker/ColorPicker';
import './viewer.css';
import InfoModal from '../infoModal.component';
import { useWindowDimensions } from '../utils/resizeHook';

export default function ViewerColorSetter(props)  {

    const [size, setSize] = useState(300);

    const windowSize = useWindowDimensions();

    useEffect(() => {
        setSize((windowSize.height*30)/100)
    }, [windowSize]);

    return (
        <InfoModal title={"Color de fondo"} show={props.show} showModal={props.showModal} hideBlackdrop={true} closeButtonTitle="Cerrar Selector" modalLabel="modalLabel" bgPanel={true}
            content={
                <ColorPicker 
                    initialColor={props.color}
                    onChange={(color) => props.changeColor(color)}
                    size={size}
                />
            }
        />
    )    
}