import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import './styles/ColorPicker.css';
import PropTypes from 'prop-types';
import { coordinatesToHS, hsToCoordinates } from './helpers/utils';

const ColorWheel = ({
  color,
  size,
  setColor,
}) => {
  const wheel = useRef(null);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const mouseDown = event => {
      if (wheel.current.contains(event.target)) {
        setEditing(true);
      }
    };
    const mouseUp = () => {
      setEditing(false);
    };
    const mouseMove = event => {
      if (editing) {
        setColor(
          coordinatesToHS(
            (event.clientX - wheel.current.getBoundingClientRect().x) / size,
            (event.clientY - wheel.current.getBoundingClientRect().y) / size,
          ),
        );
      }
    };

    const touchStart = mouseDown;
    const touchEnd = mouseUp;
    const touchMove = event => {
      if (editing) {
        var touch = event.touches[0];
        setColor(
          coordinatesToHS(
            (touch.clientX - wheel.current.getBoundingClientRect().x) / size,
            (touch.clientY - wheel.current.getBoundingClientRect().y) / size,
          ),
        );
      }
    };

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mousedown', mouseDown);
    window.addEventListener('mouseup', mouseUp);
    window.addEventListener('touchmove', touchMove);
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchend', touchEnd);

    return () => {
      window.removeEventListener('mousedown', mouseDown);
      window.removeEventListener('mouseup', mouseUp);
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('touchmove', touchMove);
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchend', touchEnd);
    
    };
  }, [editing, setColor, size]);

  const { x, y } = hsToCoordinates(color.h, color.s);

  const onMouseDown = useCallback(event => { 
    setColor(
      coordinatesToHS(
        (event.clientX - event.currentTarget.getBoundingClientRect().x) / size,
        (event.clientY - event.currentTarget.getBoundingClientRect().y) / size,
      ),
    );
  }, [setColor, size]);
 
  const onTouchStart = useCallback(event => {
    setColor(
      coordinatesToHS(
        (event.touches[0].clientX - event.currentTarget.getBoundingClientRect().x) / size,
        (event.touches[0].clientY - event.currentTarget.getBoundingClientRect().y) / size,
      ),
    );
  }, [setColor, size]);

  const onKeyDown = useCallback(event => {
    let directionX = 0, directionY = 0;
    if (event.keyCode == '38') {
      directionY = -0.05; //Up
    }
    else if (event.keyCode == '40') {
       directionY = 0.05; //Down
    }
    else if (event.keyCode == '37') {
      directionX = -0.05; //Left
    }
    else if (event.keyCode == '39') {
      directionX = 0.05; //Right
    }

    setColor(
      coordinatesToHS(x + directionX, y + directionY)
    );
  }, [setColor, size]);

  return (
    <div className="colorWheel" style={{touchAction: 'none'}}>
      <div aria-label="Rueda cambio de color." ref={wheel} className="wheel" onMouseDown={onMouseDown} onTouchStart={onTouchStart} onKeyDown={onKeyDown} 
          role="slider" aria-valuetext={"Matiz: " + Math.round(color.h * 10) / 10 + " Saturación: " + Math.round(color.s * 10) / 10} tabIndex={0}>
        <div className="handle" style={{top: y * size, left: x * size, width: size / 10, height: size / 10, border: `${size / 150}px solid black`}}/>
      </div>      
    </div>
  );
};

ColorWheel.propTypes = {
  color: PropTypes.shape({
    h: PropTypes.number,
    s: PropTypes.number,
    l: PropTypes.number,
  }),
  size: PropTypes.number.isRequired,
  setColor: PropTypes.func.isRequired,
};

ColorWheel.defaultProps = {
  color: {
    h: 0,
    s: 100,
    l: 50,
  },
};
export default ColorWheel;
