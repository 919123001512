import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

//<Router basename="/MEP2020_arte/4/"></Router>

ReactDOM.render(
    <IonApp>
        <IonReactRouter basename="app/">
            <App />
        </IonReactRouter>
    </IonApp>,

    document.getElementById('root')
);