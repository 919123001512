import React, { Fragment, useState, useEffect, useMemo, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 'calc(2.5vh + 2.5vh * var(--font-zoom))',
        lineHeight: '3vh',
    }
}));

function ModelsDesc(props) {

    const[description, setDescription] = useState();
    const[popOverAnchor, setPopOverAnchor] = useState();
    const[popOverContent, setPopOverContent] = useState("TEST");

    const ModelDescriptions = useRef();
    const classes = useStyles();

    const handlePopoverOpen = (e, text) => {
        setPopOverContent(text);
        setPopOverAnchor(e.currentTarget);
    }

    const handlePopoverClose = () =>  {
        setPopOverAnchor(null);
    }

    useMemo(()=>{
        ModelDescriptions.current = {       
            Jadebird : 
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong> Desconocido.</p>
                        <p className="screen-reader-text"> Año: del 500 antes de cristo al 800 después de cristo. </p>
                        <p aria-hidden="true"> <strong>Año: </strong> 500 a.C – 800 d.C.</p>
                        <p> <strong>Dimensiones: </strong> 3 cm x 14,8 cm. </p>
                        <p> <strong>Descripción: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace al catálogo de la sala de jade. Museo del Jade.">
                                <a aria-describedby="tt1" className="link" href="https://museodeljade.ins-cr.com/Recursos/PDF/Fichas SALA JADE.pdf" target="_blank" rel="noreferrer"> Talla en Jadeita</a>
                            </Tooltip>
                            <span>, forma </span> 
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Con forma de hacha.")}>hachoide</span>
                            <span> con figura </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Con forma de ave.")}>aviforme</span>                
                            <span> estilizada, con plumaje y perforación </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Dos conos opuestos / conectados por la base.")}>bicónica</span>          
                            <span> a los costados. </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Recorrido Virtual, Sala Precolombina, Museo Nacional de Costa Rica.">
                                <a aria-describedby="tt2" className="link" href="https://www.museocostarica.go.cr/interactivo/sala-historia-precolombina/" target="_blank" rel="noreferrer"> Arte precolombino. </a>
                            </Tooltip>
                        </p>
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt3" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo del Jade.">
                                <a aria-describedby="tt3" className="link" href="https://museodeljade.ins-cr.com/" target="_blank" rel="noreferrer"> Museo del Jade.</a>
                            </Tooltip>
                        </p>
                    </div></div>  
                </Fragment>,
            FiguraMasculina :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>Desconocido.</p>
                        <p> <strong>Año: </strong>Sin año.</p>
                        <p> <strong>Dimensiones: </strong> 36 x 72 cm.</p>
                        <p> <strong>Descripción: </strong>
                            <span>Figura masculina,</span>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace al artículo; Lítica, Museo Nacional.">
                                <a aria-describedby="tt1" className="link" href="https://www.museocostarica.go.cr/nuestro-trabajo/colecciones/arqueologia/litica/" target="_blank" rel="noreferrer"> talla en piedra</a>
                            </Tooltip> 
                            <span> andesita, procedente de la vertiente atlántica.</span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace a recorrido virtual, sala precolombina, Museo Nacional de Costa Rica.">
                                <a aria-describedby="tt2" className="link" href="https://www.museocostarica.go.cr/interactivo/sala-historia-precolombina/" target="_blank" rel="noreferrer"> Arte precolombino.</a>
                            </Tooltip>                        
                        </p>  
                        <p>
                            <span> Encuéntrala en </span>
                            <Tooltip id="tt3" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo del Jade.">
                                <a aria-describedby="tt3" className="link" href="https://museodeljade.ins-cr.com/" target="_blank" rel="noreferrer"> Museo del Jade.</a>
                            </Tooltip> 
                        </p>
                    </div></div>  
                </Fragment>, 
            VirgenMaria :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>Desconocido.</p>
                        <p> <strong>Año: </strong>Posiblemente 1780.</p>
                        <p> <strong>Dimensiones: </strong> 152 x 46 cm.</p>
                        <p> <strong>Descripción: </strong>
                            <span>Fiel ejemplo del arte de la </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Arte y técnica de esculpir y pintar imágenes religiosas con un fuerte realismo marcado en el rostro, el color de la piel, llagas, y la adición de ojos de vidrio, cabello y pestañas naturales. Se representan personajes y escenas derivadas del repertorio iconográfico cristiano; santos, vírgenes, mártires.")}>imaginería</span>
                            <span>, busca conmover al espectador a través de posturas afligidas con un severo naturalismo identificado en el colorido de la carne y llagas, aunado al uso de ojos de vidrio, cabellos y pestañas de cabello natural, lágrimas de resina o cristal (ver en Fotodetalle). También llamadas figuras “Cap i pota”.  </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"De varios colores")}>Policromía</span>
                            <span> original.</span>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace al artículo; Un breve momento en la historia de Costa Rica.">
                                <a aria-describedby="tt1" className="link" href="https://www.mep.go.cr/sites/default/files/imagineria_0.pdf" target="_blank" rel="noreferrer"> Arte de la Colonia.</a>
                            </Tooltip>                       
                        </p>
                        <p>
                            <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo Nacional de Costa Rica.">
                                <a aria-describedby="tt2" className="link" href="https://www.museocostarica.go.cr" target="_blank" rel="noreferrer"> Museo Nacional de Costa Rica.</a>
                            </Tooltip> 
                        </p>
                    </div></div>    
                </Fragment>,
            Petronila :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a dato biográfico.">
                                <a aria-describedby="tt1" className="link" href="http://www.sinabi.go.cr/DiccionarioBiograficoDetail/biografia/11" target="_blank" rel="noreferrer"> Juan Mora González</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1875.</p>
                        <p> <strong>Dimensiones: </strong> 38.4 x 39 cm y 17 cm de profundidad.</p>
                        <p> <strong>Descripción: </strong>
                            <span>Talla en madera, </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"De varios colores")}>policromada</span>
                            <span>, con ojos de vidrio y botones de metal. Arte finales siglo XIX, inicios de la república. Representa el cambio en la estética del siglo XIX, de un arte dedicado a la fe cristiana (  </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Arte y técnica de esculpir y pintar imágenes religiosas con un fuerte realismo marcado en el rostro, el color de la piel, llagas, y la adición de ojos de vidrio, cabello y pestañas naturales. Se representan personajes y escenas derivadas del repertorio iconográfico cristiano; santos, vírgenes, mártires.")}>imaginería</span>
                            <span> ), hacia un arte </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Independiente de cualquier confesión religiosa.")}>laico</span>.
                        </p>
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo Nacional de Costa Rica.">
                                <a aria-describedby="tt2" className="link" href="https://www.museocostarica.go.cr" target="_blank" rel="noreferrer"> Museo Nacional de Costa Rica.</a>
                            </Tooltip> 
                        </p>
                    </div></div>   
                </Fragment>,
            Venus :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a dato biográfico.">
                                <a aria-describedby="tt1" className="link" href="http://www.sinabi.go.cr/DiccionarioBiograficoDetail/biografia/9" target="_blank" rel="noreferrer"> Fadrique Gutiérrez Flores</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1863.</p>
                        <p> <strong>Dimensiones: </strong> 149 cm, x 42 x 39 cm.</p>
                        <p> <strong>Descripción: </strong>
                            <span>Talla en piedra. Arte finales siglo XIX inicios de la república. Representativa del cambio en la estética costarricense de lo religioso </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Arte y técnica de esculpir y pintar imágenes religiosas con un fuerte realismo marcado en el rostro, el color de la piel, llagas, y la adición de ojos de vidrio, cabello y pestañas naturales. Se representan personajes y escenas derivadas del repertorio iconográfico cristiano; santos, vírgenes, mártires.")}>imaginería</span>
                            <span> a lo </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Que no es sagrado, ni sirve para fines sagrados.")}>profano</span>.
                        </p> 
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo Nacional de Costa Rica.">
                                <a aria-describedby="tt2" className="link" href="https://www.museocostarica.go.cr" target="_blank" rel="noreferrer"> Museo Nacional de Costa Rica.</a>
                            </Tooltip> 
                        </p>
                    </div></div>  
                </Fragment>,
            AngelDeGloria :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>Desconocido.</p>
                        <p> <strong>Año: </strong>Sin año.</p>
                        <p> <strong>Dimensiones: </strong> 57 x 26 x 16.5 cm.</p>
                        <p> <strong>Descripción: </strong>
                            <span> Talla directa en madera, </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"De varios colores")}>policromada</span>.
                            <span> Arte religioso, inicios del siglo XX. </span>
                        </p>  
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                                <a aria-describedby="tt1" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip> 
                        </p>
                    </div></div>   
                </Fragment>,
            Berta :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Título: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Juan Manuel Sánchez y su musa Berta.">
                                <a aria-describedby="tt1" className="link" href="http://www.mac.go.cr/files/downloads/84_jmsissuu.pdf" target="_blank" rel="noreferrer"> Berta </a> 
                            </Tooltip>
                            <span> a la manera de </span> 
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Gargallo">
                                <a aria-describedby="tt2" className="link" href="http://www.zaragoza.es/contenidos/museos/gargallo/Guia_didac_Gargallo.pdf" target="_blank" rel="noreferrer"> Gargallo.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt3" classes={{ tooltip: classes.tooltip }} title="Enlace a artículo con dato biográfico.">
                                <a aria-describedby="tt3" className="link" href="http://www.sinabi.go.cr/DiccionarioBiograficoDetail/biografia/559" target="_blank" rel="noreferrer">Juan Manuel Sánchez Barrantes.</a>
                            </Tooltip>       
                        </p>
                        <p> <strong>Año: </strong> 1936.</p>
                        <p> <strong>Dimensiones: </strong> 28 x 30.5 x 14.5 cm.</p>
                        <p> <strong>Descripción: </strong> Lámina de metal (Latón) recortada. Rostro estilizado de mujer (esposa del artista). Refiere a ideales estéticos de las corrientes modernas europeas que poco a poco se introducen en Costa Rica.</p>  
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt4" classes={{ tooltip: classes.tooltip }} title="Sitio oficial del museo de arte costarricense.">
                                <a aria-describedby="tt4" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip> 
                        </p>
                    </div></div>     
                </Fragment>,
            Silla :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a artículo con dato biográfico">
                                <a aria-describedby="tt1" className="link" href="https://historico.semanariouniversidad.com/suplementos/forja/juan-luis-rodrguez-sibaja-sus-primeros-80-aos/" target="_blank" rel="noreferrer">Juan Luis Rodríguez Sibaja.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1969.</p>
                        <p> <strong>Dimensiones: </strong> 210 x 98.5 x 86 cm.</p>
                        <p> <strong>Descripción: </strong>
                            <span>Talla en madera y pintura.  Ejemplo importante de escultura </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"Realizado a partir de diversos materiales. Las características de los materiales en sí mismos, son las responsables de la representatividad de las ideas, emociones, expresiones en la obra. (Matérico: que emplea como medio de expresión materiales distintos a los utilizados tradicionalmente en la obra de arte. RAE).")}>Polimatérica</span>.
                        </p> 
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                                <a aria-describedby="tt2" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip> 
                        </p>
                    </div></div>    
                </Fragment>,
            Armadillo :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a artículo con dato biográfico.">
                                <a aria-describedby="tt1" className="link" href="https://observador.cr/jose-sancho-del-nino-que-contemplaba-el-estero-y-hoy-es-el-magon/" target="_blank" rel="noreferrer">José Sancho.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1983.</p>
                        <p> <strong>Dimensiones: </strong> 42 x 67 x 18 cm (sin base), 127.5 x 27 x 29 cm (con base).</p>
                        <p> <strong>Descripción: </strong>
                            <span>La estilización de las formas y temáticas relacionadas con la naturaleza, con referentes relacionados al arte precolombino y al artista</span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace a artículo acerca de Constantino Brancussi.">
                                <a aria-describedby="tt2" className="link" href="https://historia-arte.com/artistas/constantin-brancusi" target="_blank" rel="noreferrer"> Constanti Brancusi.</a>
                            </Tooltip> 
                        </p> 
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt3" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                                <a aria-describedby="tt3" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip>  
                        </p>
                    </div></div>    
                </Fragment>,
            MujerConTubos :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Título: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a video acerca de esta obra.">
                                <a aria-describedby="tt1" className="link" href="https://www.youtube.com/watch?v=x71StGp2uU4)" target="_blank" rel="noreferrer"> Mujer con tupos.</a>
                            </Tooltip>  
                        </p>
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace a artículo con dato biográfico.">
                                <a aria-describedby="tt2" className="link" href="https://si.cultura.cr/personas/roberto-lizano.html" target="_blank" rel="noreferrer">Roberto Lizano.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1989.</p>
                        <p> <strong>Dimensiones: </strong> 141 x 115.6 x 12 cm.</p>
                        <p> <strong>Descripción: </strong>Técnica: lápiz, acrílico, collage, joyas y textil sobre cartón.</p>
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt3" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                                <a aria-describedby="tt3" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip>  
                        </p>
                    </div></div>   
                </Fragment>,
            Eva :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a video de la obra de Marisel Jiménez.">
                                <a aria-describedby="tt1" className="link" href="https://www.youtube.com/watch?v=Dk_p9Sm5Iw0" target="_blank" rel="noreferrer">Marisel Jiménez.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1990</p>
                        <p> <strong>Dimensiones: </strong> 130 x 45 cm.</p>
                        <p> <strong>Técnica: </strong>Escultura, fundición.</p>
                        <p> <strong>Descripción: </strong>Escultura con la técnica de la fundición.</p>
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                                <a aria-describedby="tt2" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip> 
                        </p>
                    </div></div>  
                </Fragment>,
            BustoEva :
            <Fragment>
                <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                    <p> <strong>Autor: </strong>
                        <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a video de la obra de Marisel Jiménez.">
                            <a aria-describedby="tt1" className="link" href="https://www.youtube.com/watch?v=Dk_p9Sm5Iw0" target="_blank" rel="noreferrer">Marisel Jiménez.</a>
                        </Tooltip>
                    </p>
                    <p> <strong>Año: </strong>1990</p>
                    <p> <strong>Dimensiones: </strong> 130 x 45 cm.</p>
                    <p> <strong>Técnica: </strong>Escultura, fundición.</p>
                    <p> <strong>Descripción: </strong> Escultura con la técnica de la fundición. </p>
                    <p> <span> Encuéntrala en </span>
                        <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                            <a aria-describedby="tt2" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                        </Tooltip> 
                    </p>
                </div></div>
            </Fragment>,
            Leona :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a catálogo de obras.">
                                <a aria-describedby="tt1" className="link" href="http://www.artecostarica.cr/artistas/zeledon-varela-nestor" target="_blank" rel="noreferrer">Néstor Zeledón Varela.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1930.</p>
                        <p> <strong>Dimensiones: </strong> 102 x 42 x 42 cm.</p>
                        <p> <strong>Descripción: </strong>
                            <span>Talla en madera. Al igual que otras obras de la época, el uso de maderas autóctonas y otros materiales no tradicionales revoluciona el arte costarricense.</span><br/>
                            <span>En el fotodetalle se aprecia el rostro del cachorro entre las piernas de la Leona.</span>
                        </p> 
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                                <a aria-describedby="tt2" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip>  
                        </p>
                    </div></div>  
                </Fragment>,
            DeVidrioLaCabecera :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a referencia de la exposición Centroamérica; deseo de lugar.">
                                <a aria-describedby="tt1" className="link" href="https://muac.unam.mx/exposicion/virginia-perez-ratton" target="_blank" rel="noreferrer">Virginia Pérez-Ratton.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1994.</p>
                        <p> <strong>Dimensiones: </strong> 90 x 120 x 180 cm.</p>
                        <p> <strong>Descripción: </strong> Ensamblaje (metal, vidrio y tela).</p>
                        <p>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="De vidrio la cabecera">
                                <a aria-describedby="tt2" className="link" href="http://repositorio.sibdi.ucr.ac.cr:8080/jspui/bitstream/123456789/15358/1/PRV004.pdf" target="_blank" rel="noreferrer"> De vidrio la cabecera.</a>
                            </Tooltip> 
                        </p>
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt3" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte y Diseño Contemporáneo.">
                                <a aria-describedby="tt3" className="link" href="https://www.madc.cr/" target="_blank" rel="noreferrer"> Museo de Arte y Diseño Contemporáneo.</a>
                            </Tooltip> 
                        </p>
                    </div></div>   
                </Fragment>,
            Horus :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a video referencia de la obra de Marisel Jiménez.">
                                <a aria-describedby="tt1" className="link" href="https://www.youtube.com/watch?v=Dk_p9Sm5Iw0" target="_blank" rel="noreferrer">Marisel Jiménez.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>Sin fecha.</p>
                        <p> <strong>Dimensiones: </strong> Banco: 63 x 35 x 37.5 cm. Cabeza: 20.5 x 24 x 27 cm.</p>
                        <p> <strong>Descripción: </strong>Ensamble (escultura en madera y banco).</p>  
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte y Diseño Contemporáneo.">
                                <a aria-describedby="tt2" className="link" href="https://www.madc.cr/" target="_blank" rel="noreferrer"> Museo de Arte y Diseño Contemporáneo.</a>
                            </Tooltip> 
                        </p>
                    </div></div>  
                </Fragment>,
            SimbolosPatrios :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace al artículo; La frontera del escape, Pedro Arrieta.">
                                <a aria-describedby="tt1" className="link" href="https://www.redalyc.org/pdf/166/16613114.pdf" target="_blank" rel="noreferrer">Pedro Arrieta.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1997</p>
                        <p> <strong>Dimensiones: </strong> 130 x 122 x 60 cm.</p>
                        <p> <strong>Descripción: </strong> Arte objeto (vitrina con balón de fútbol, botella de aguardiente "Cacique" y reproducción de Virgen de los Ángeles tallada a mano).</p> 
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte y Diseño Contemporáneo.">
                                <a aria-describedby="tt2" className="link" href="https://www.madc.cr/" target="_blank" rel="noreferrer"> Museo de Arte y Diseño Contemporáneo.</a>
                            </Tooltip> 
                        </p>
                    </div></div>   
                </Fragment>,
            LaQuintaEstacion :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a video referencia de la obra de Marisel Jiménez.">
                                <a aria-describedby="tt1" className="link" href="https://www.youtube.com/watch?v=Dk_p9Sm5Iw0" target="_blank" rel="noreferrer">Marisel Jiménez.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1988.</p>
                        <p> <strong>Dimensiones: </strong> 227 x 120 x 120 cm.</p>
                        <p> <strong>Descripción: </strong> Instalación. Objetos de madera intervenidos.</p>
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte y Diseño Contemporáneo." >
                                <a aria-describedby="tt2" className="link" href="https://www.madc.cr/" target="_blank" rel="noreferrer"> Museo de Arte y Diseño Contemporáneo.</a>
                            </Tooltip> 
                        </p>
                    </div></div>   
                </Fragment>,
            LaFotoDeSus15 :
				<Fragment>
					<div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace a artículo con dato biográfico.">
                                <a aria-describedby="tt1" className="link" href="https://historico.semanariouniversidad.com/suplementos/forja/apuntes-sobre-la-obra-de-leda-astorga/" target="_blank" rel="noreferrer">Leda Astorga.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1991.</p>
                        <p> <strong>Dimensiones: </strong> 40 x 32 x 38 cm.</p>
                        <p> <strong>Descripción: </strong>
                            <span>Escultura en concreto </span>
                            <span role="mark" tabIndex="0" className="dictionary-span" onClick={(e) => handlePopoverOpen(e,"De varios colores")}>policromado</span>.
                        </p>  
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                                <a aria-describedby="tt2" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip>
                        </p>
                    </div></div>  
				</Fragment>,
            Patriarca :
                <Fragment>
                    <div role="document" tabIndex="0" id="info-modal-content"> <div role="main" aria-label="Ficha de la obra.">
                        <p> <strong>Autor: </strong>
                            <Tooltip id="tt1" classes={{ tooltip: classes.tooltip }} title="Enlace al catálogo exposición.">
                                <a aria-describedby="tt1" className="link" href="https://issuu.com/madc/docs/juegos-y-ritos_adolfo-siliezar" target="_blank" rel="noreferrer">Adolfo Siliézar.</a>
                            </Tooltip>
                        </p>
                        <p> <strong>Año: </strong>1998.</p>
                        <p> <strong>Dimensiones: </strong> 128 x 160 x 30 cm.</p>
                        <p> <strong>Descripción: </strong> Ensamble en madera quemada.</p> 
                        <p> <span> Encuéntrala en </span>
                            <Tooltip id="tt2" classes={{ tooltip: classes.tooltip }} title="Enlace al sitio web oficial del Museo de Arte Costarricense.">
                                <a aria-describedby="tt2" className="link" href="http://www.mac.go.cr/" target="_blank" rel="noreferrer"> Museo de Arte Costarricense.</a>
                            </Tooltip>
                        </p>
                    </div></div>    
                </Fragment>,
        }
    },[ModelDescriptions]);

    useEffect(() => {
        switch (props.name) {
            case "Eva":
                setDescription(ModelDescriptions.current.Eva);
                break;
            case "Jadebird":
                setDescription(ModelDescriptions.current.Jadebird);
                break;
            case "Leona":
                setDescription(ModelDescriptions.current.Leona);
                break;
            case "FiguraMasculina":
                setDescription(ModelDescriptions.current.FiguraMasculina);
                break;
            case "Berta":
                setDescription(ModelDescriptions.current.Berta);
                break;
            case "Venus":
                setDescription(ModelDescriptions.current.Venus);
                break;
            case "MujerTubos":
                setDescription(ModelDescriptions.current.MujerConTubos);
                break;
            case "Petronila":
                setDescription(ModelDescriptions.current.Petronila);
                break;
            case "Horus":
                setDescription(ModelDescriptions.current.Horus);
                break;
            case "Angel":
                setDescription(ModelDescriptions.current.AngelDeGloria);
                break;
            case "Armadillo":
                setDescription(ModelDescriptions.current.Armadillo);
                break;
            case "DeVidrioLaCabecera":
                setDescription(ModelDescriptions.current.DeVidrioLaCabecera);
                break;
            case "Silla":
                setDescription(ModelDescriptions.current.Silla);
                break;
            case "Simbolos":
                setDescription(ModelDescriptions.current.SimbolosPatrios);
                break;
            case "Patriarca":
                setDescription(ModelDescriptions.current.Patriarca);
                break;
            case "LaQuintaEstacion":
                setDescription(ModelDescriptions.current.LaQuintaEstacion);
                break;
            case "Virgen":
                setDescription(ModelDescriptions.current.VirgenMaria);
                break;
            case "LaFotoDeSus15":
                setDescription(ModelDescriptions.current.LaFotoDeSus15);
                break;
            case "EvaBusto":
                setDescription(ModelDescriptions.current.BustoEva);
            break;
            default:
                break;
        }
    }, [props.name]);
    
    const open = Boolean(popOverAnchor);

    return (
        <Fragment>
            {description}
            <Popover role="dialog" id="simple-popover" open={open} anchorEl={popOverAnchor} onClose={handlePopoverClose} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Typography className={`small-font ${classes.typography}`}> {popOverContent}</Typography>
            </Popover>
        </Fragment>
    );   
  
}

export default (ModelsDesc);