import React, {useState,useEffect,useRef, useMemo} from 'react';
import { connect } from 'react-redux';
import { setParticipante } from '../redux/actions/authActions';
import {Container, Row, Col} from 'reactstrap';
import "./questions.css";
import Image from 'react-bootstrap/Image';
import Guard from '../resources/Guardas/guarda1.png';
import Title from '../resources/Titulos/TitleClavesdelArte.svg';
import LeftContainer from './utils/leftContainer.component';
import { Fragment } from 'react';
import questionFile from '../resources/questions.json';
import {Blue,Yellow} from '../constants';
import HelpModal from './helpModal.component';
import IconButton from './iconButton.component';
import FontSizeModal from './fontSizeModal.component';
import uuid from 'device-uuid';

function Questions(props){
    const [currentIndex,setCurrentIndex] = useState(0);
    const questions = useRef(questionFile.Items);
    const [q,setQ] = useState(null);
    const [helpOpen, toggleHelp] = useState(false);
    const isMounted = useRef(false);

    const currentAnswers = useRef([]);
    const fontSizeModal = useRef();

    //const alreadyAnswer = useRef(props.auth.alreadyAnswer);

    const help = useRef()

    const {setParticipante} = props;
    const {history} = props;
    
    useMemo(()=>{
        help.current = 
            <Fragment>
                <Container className="center-object" style={{maxWidth:'100%'}}>
                    <Row className="center-object">
                        <Col className="horizontal-center">                        
                            <div id="helpDesc">
                                <p>Respondé las preguntas presionando una de las opciones o bien, da clic al botón avanzar para continuar.</p> 
                                <p>El botón “Aa” permite cambiar el tamaño de la letra en pantalla.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
        </Fragment>
    },[help])

    const skip = ()=>{
        history.push('/menu');
    }

    useEffect(()=>{
        if(questions.current[currentIndex]){
            const text = questions.current[currentIndex].Question;
            const answerStyles = questions.current[currentIndex].Styles;
            const answers = questions.current[currentIndex].Answers;

            let answerIndex = 0;

            const nextQuestion = (value)=> {
                currentAnswers.current[currentIndex] = value;
                setCurrentIndex(currentIndex+1);

                if(currentIndex+1 >= questions.current.length){    
                    history.push('/menu')                
                    setParticipante({tipo_usuario:currentAnswers.current[0],nivel:currentAnswers.current[1],tipo_institucion:currentAnswers.current[2],uuid:uuid.DeviceUUID().get()}).then(
                        (res)=>{
                            console.log(res);
                        }
                    ).catch(
                        (err)=>{
                            console.log(err);
                        }
                    )
                }
            }
            let qKey = 0;
            setQ(<Fragment key={currentIndex}>
                    {text.map(
                                (option) => { 
                                    if(option ===  "*"){
                                        let i = answerIndex;
                                        answerIndex++;
                                        return <button key={"question_"+currentIndex+"_"+i+"_"+qKey++} className={`svgbtnMid ${'svgbtn-'+answerStyles[i]}`} onClick={()=>{nextQuestion(answers[i])}} aria-label={text[0] +' '+ answers[i]+ ". Opción "+answerIndex} type="button"/>;
                                    }else{
                                        return <span key={"option_"+currentIndex+"_"+qKey++}>{option}</span>;
                                    }
                                })
                    }
                </Fragment>
            );
        }
    }
    ,[currentIndex,questions,history,setParticipante]);

    useEffect(()=>{
        if(q){
            if(isMounted.current){
                var focusable = document.querySelectorAll('button');
                focusable[0].focus();
            } else {
                document.getElementById("questions-screen").focus();
                isMounted.current = true;
            }
        }
    }, [q]);

   	return (
        <div id="questions-screen" tabIndex="-1" role="application" aria-labelledby="title" aria-describedby="descr">
            <div className="right"><Image className='rightlogo' src={Guard} aria-hidden="true"/></div>
            <LeftContainer>
                <Fragment>
                    <Row className='row-full-width title'>
                        <Image id="title" style={{width:'100%'}} src={Title} alt="Las claves del arte costarricense."></Image>
                    </Row>
                    <Row className="middle">
                        <Row id="descr">
                            <p className="question-paragraph-text">A través de los diferentes insumos presentados en este recurso, explorá las singularidades del arte costarricense.</p>
                            <p className="question-paragraph-text">Examiná con atención los elementos compositivos en las obras en 3D y estudiá las características históricas que dieron forma a la magnífica obra artística costarricense.</p>
                            <p className="question-paragraph-text">Antes de empezar respondé estas preguntas:</p>
                        </Row>
                        <Row className="vertical-center question-text" style={{width: '100%', justifyContent: 'center'}}> 
                            {q}
                        </Row>
                    </Row>                  
                    <Row className="bottom-section">
                        <Col className="flex-begin"> <IconButton className='svgbtn fontSize-svg' color={Yellow} function={()=>{fontSizeModal.current.open()}} popover="Cambiar tamaño de letra" /> </Col>
                        <Col className="flex-end">  
                            <IconButton className='svgbtn help-svg' color={Blue} function={()=>{toggleHelp(true)}} popover="Ayuda" />
                            <IconButton className='svgbtn next-svg' color={Yellow} function={skip} popover="Avanzar" />
                        </Col>
                    </Row>
                </Fragment>
            </LeftContainer>
            <HelpModal show={helpOpen} showModal={toggleHelp} content={help.current} descrBy="helpDesc"></HelpModal>
            <FontSizeModal ref={fontSizeModal}/>
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {setParticipante}
)(Questions);