import React, { useCallback, useEffect, useState, useRef } from 'react';
import './styles/ColorPicker.css';
import PropTypes from 'prop-types';
import ColorWheel from './ColorWheel';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    width: '90%'
  },
  track: {
    color: 'transparent'
  },
  rail: {
    backgroundColor: 'transparent'
  },
  thumb: {
    width: '7vh',
    height: '7vh',
    marginTop: 0,
    marginLeft: '-10%',
    backgroundColor: 'white',
    border: '1px solid black',
  },  
});

const ColorPicker = ({
  size,
  initialColor,
  onChange,
}) => {
  const [pickedColor, setPickedColor] = useState({
    hsl: { h: 0, s: 100, l: 50 }
  });

  const classes = useStyles();
  const L = useRef(initialColor.l);

  useEffect(() => {
    if (initialColor !== null && initialColor !== undefined) {
      setPickedColor({ hsl : initialColor });
    } else {
      setPickedColor({ hsl: { h: 0, s: 0, l: 50 } });
    } 
  },[initialColor]);

  const setColorFromWheel = useCallback(hsl => {
    const h = parseFloat((hsl.h === undefined ? pickedColor.hsl.h : hsl.h).toFixed(2));
    const s = parseFloat((hsl.s === undefined ? pickedColor.hsl.s : hsl.s).toFixed(2));
    const l = parseFloat((hsl.l === undefined ? pickedColor.hsl.l : hsl.l).toFixed(2));
    setPickedColor({ hsl: { h, s, l } });
    onChange({ hsl: { h, s, l } });
  }, [onChange, pickedColor.hsl]);

  return (
    <div className="colorPickerContainer">
      <p id="modalLabel" hidden> Selector de Color de Fondo </p>
      <div className="sliderBackground" style={{background: `linear-gradient(to left,white,hsl(${pickedColor.hsl.h},${pickedColor.hsl.s}%,50%), black)`}}>
        <Slider aria-label="Cambio de brillo" defaultValue={L.current} onChangeCommitted={(e, value) => setColorFromWheel({ l: value })} 
                classes={{root: classes.root, track: classes.track, rail: classes.rail, thumb: classes.thumb}} />
      </div>
      <div className="outerContainer" style={{height: size+"px", width: size+"px"}}>
        <ColorWheel color={pickedColor.hsl} size={size} setColor={setColorFromWheel}/>
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  size: PropTypes.number,
  initialColor: PropTypes.oneOfType([
    PropTypes.shape({ h: PropTypes.number, s: PropTypes.number, l: PropTypes.number })
  ]),
  onChange: PropTypes.func,
};

ColorPicker.defaultProps = {
  size: 100,
  initialColor: {h:0, s:0, l:50},
  onChange: (() => {}),
};

export default ColorPicker;
