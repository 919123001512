import React, {Component,Fragment} from 'react';
import { ART_TYPES,MODELS } from '../constants';
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Button } from 'reactstrap';
import ArtInfo from './art-info.component';
import Phrase from './phrase.component';
import LeftContainer from './utils/leftContainer.component';
import Image from 'react-bootstrap/Image';
import Guard from '../resources/Guardas/guarda2.png';
import IconButton from './iconButton.component';
import {Red,Blue,Yellow} from '../constants';
import "./art.css";
import HelpModal from './helpModal.component';
import { isPlatform } from '@ionic/react';
import FontSizeModal from './fontSizeModal.component';

class Art extends Component {

    constructor(props) {
        super(props);    
        this.state = {
            showInfo: false,
            showPhrase: false,
            helpOpen:false,
        };

        this.fontSizeModal = React.createRef();

        this.help = <Fragment>
                        <Container id="helpDesc" className="center-object" style={{maxWidth:'100%'}}>
                            <Row className="center-object">
                                <p>Presioná sobre una de las imágenes para ingresar a su versión en 3D.</p>
                                <p>El botón “Aa” permite cambiar el tamaño de la letra en pantalla.</p>
                            </Row>
                        </Container>
                    </Fragment>
    }

    componentDidMount(){
        if(!this.props.location.state){
            return;
        }

        let value = this.props.location.state.showInfo;
        this.props.location.state.showInfo = false;
        this.props.history.replace(this.props.location);

        if(value === true){
            this.setState({showPhrase : value});
            this.setState({showInfo : value});
        }
    }

    componentWillUnmount(){
        this.setState({showPhrase : false});
        this.setState({showInfo : false});
    }

    goBack(){
        this.props.history.goBack();
    };

    onPhraseEnd(){
        this.setState({showPhrase: false})
    }

    toggleInfo(){
        this.setState((state)=> ({
            showInfo : !state.showInfo
        }));
    }

    toggleHelp(){
        this.setState((state)=> ({
            helpOpen : !state.helpOpen
        }));
    }
    
    openModel(value){
        this.props.history.push('/viewer', { type: this.props.location.state.key, model: value});
    }

    getTitle(value){
        switch(value){
            case "arte-premoderno":
                return (require('../resources/Titulos/TitleArtePreModerno.svg').default);
            case "arte-moderno":
                return (require('../resources/Titulos/TitleArteModerno.svg').default);
            case "arte-contemporaneo":
                return (require('../resources/Titulos/TitleArteContemporaneo.svg').default);
            default:
                return (require('../resources/Titulos/TitleArtePreModerno.svg').default);
        }
    }

    getAlt(value){
        switch(value){
            case "arte-premoderno":
                return ("Arte premoderno costarricense");
            case "arte-moderno":
                return ("Arte moderno costarricense");
            case "arte-contemporaneo":
                return ("Arte contemporaneo costarricense");
            default:
                return ("Arte premoderno costarricense");
        }
    }

    render() {
        const hasParams = this.props.location.state !== undefined && this.props.location.state.hasOwnProperty('key');

        if(!hasParams){
            return(<Redirect to={{pathname: "/menu"}} />);
        }

        const key = this.props.location.state.key;

        if(this.state.showInfo === true && this.state.showPhrase === true){
            return(
                <>
                    <div className="right"><Image className='rightlogo2' src={Guard} aria-hidden="true"/></div>
                    <LeftContainer>
                        <Phrase onEnd={this.onPhraseEnd.bind(this)}></Phrase>
                    </LeftContainer>
                </>
            );
        }
       
        return (
            <div role="application" aria-label={this.getAlt(key)}>
                <div className="right"><Image className='rightlogo2' src={Guard} aria-hidden="true"/></div>
                <LeftContainer>
                    <>
                        <Row  style={{marginBottom:'5vh'}}>
                            <Image style={{width:'30vw'}} src={ this.getTitle(key)} alt={this.getAlt(key)}></Image>
                        </Row>
                        <Row className="row-full-width middle middle-full">
                            {ART_TYPES[key].models.map( (model, index) => {
                                return (
                                    <Col className="horizontal-center" key={index}>
                                        <Button className='art-model-container' onClick={this.openModel.bind(this, model)} aria-label={model !== "" ? MODELS[model].title : "Pendiente"} type="button">
                                            { model !== "" && MODELS[model].image!=="" ? 
                                                <div className="art-model-container-model" style={{"backgroundImage":`url(${require("../resources/ModelButtons/"+MODELS[model].image).default})`}}/> :
                                                <div className="art-model-container-model">
                                                    {<img alt={model !== "" ? MODELS[model].title : "Pendiente"} src={process.env.PUBLIC_URL+"/logo512.png"} className="art-model-container-thumbnail"/>}
                                                </div>
                                            }
                                            <p className="small-font">{model !== "" ? MODELS[model].title : "Pendiente"}</p>
                                        </Button> 
                                    </Col>
                                );
                            })}
                        </Row>
                        <Row className="bottom-section">
                            <Col className="flex-begin">
                                <IconButton className='svgbtn fontSize-svg' color={Yellow} function={()=>{this.fontSizeModal.current.open()}} popover="Cambiar tamaño de letra" /> 
                            </Col>
                            <Col className="flex-end">
                                <IconButton className='svgbtn help-svg' color={Red} function={this.toggleHelp.bind(this)} popover="Ayuda"/>
                                <IconButton className={isPlatform("capacitor")?'svgbtn camera-svg':'svgbtn galery-svg'} color={Blue} function={this.goBack.bind(this)} popover="Galerias de arte"/>
                                <IconButton className="svgbtn info-svg" color={Yellow} function={this.toggleInfo.bind(this)} popover="Información"/>
                            </Col>
                        </Row>
                    </>
                </LeftContainer>
                <ArtInfo show={this.state.showInfo} showModal={this.toggleInfo.bind(this)} art={key}></ArtInfo>
                <HelpModal show={this.state.helpOpen} showModal={this.toggleHelp.bind(this)} content={this.help} descrBy="helpDesc"></HelpModal>
                <FontSizeModal ref={this.fontSizeModal}/>
            </div>
		);
	}
}

export default (Art);