import React, {useRef} from 'react';
import './viewer.css';
import {Red,Blue,Yellow} from '../../constants';
import IconButton from '../iconButton.component';
import {Row, Col} from 'reactstrap';
import FontSizeModal from '../fontSizeModal.component';

export default function ViewerControls(props) {
    const fontSizeModal = useRef();

    return (
        <div id="controls" className='bottom-section'>
            <Col className="flex-begin"> 
                <IconButton className='svgbtn back-svg' color={Red} function={()=>{props.goBack();}} popover="Atrás"/>
                <IconButton className='svgbtn help-svg' color={Blue} function={()=>{props.openHelp(true)}} popover="Ayuda"/>
                <IconButton className='svgbtn content-svg' color={Yellow} function={()=>{props.openInfo(true)}} popover="Ficha de la obra"/>
                {props.openPicture != null ? <IconButton className='svgbtn modelPic-svg' color={Red} function={()=>{props.openPicture(true)}} popover="Fotodetalle"/>:<></>}
                {props.openSubModel != null ? <IconButton className={props.subModelIndex%2 !== 0 ? 'svgbtn bust-svg':'svgbtn full-svg'} color={Blue} function={()=>{props.openSubModel(true)}} popover={props.subModelName}/>:<></>}
            </Col>
            <Col className="flex-end"> 
                <IconButton className='svgbtn fontSize-svg' color={Yellow} function={()=>{fontSizeModal.current.open()}} popover="Cambiar tamaño de letra" /> 
                <IconButton className='svgbtn colorPalete-svg' color={Blue} function={() => { props.openSetter(!props.isOpen)}} popover="Selector de color de fondo"/>
            </Col>
            <FontSizeModal ref={fontSizeModal}/>
        </div>
    );
}

// style={{backgroundColor: props.lightness > 70 ? 'black' : 'transparent'}}