import React,{useMemo,useRef,useState,Fragment } from 'react';
import { ART_TYPES } from '../constants';
import {Container,Row, Col } from 'reactstrap';
import Title from '../resources/GaleriasDeArte/GaleriasdeArte.svg';
import LeftContainer from './utils/leftContainer.component';
import Image from 'react-bootstrap/Image';
import Guard from '../resources/Guardas/guarda3.png';
import './menu.css';
import {Yellow} from '../constants';
import HelpModal from './helpModal.component';
import IconButton from './iconButton.component';
import FontSizeModal from './fontSizeModal.component';

export default function Menu(props) {

    const [helpOpen, toggleHelp] = useState(false);
    const help = useRef();
    const fontSizeModal = useRef();
    
    useMemo(()=>{
        help.current = 
            <Fragment>
                <Container id="helpDesc" className="center-object" style={{maxWidth:'100%'}}>
                    <Row className="center-object">
                        <p>Para ingresar a la galería con los modelos 3D, presioná sobre uno de los nombres: Pre moderno, Moderno o Contemporáneo.</p>
                        <p>Para conocer aspectos históricos y teóricos de cada modelo de arte, presioná sobre: Modelos del Arte Costarricense. </p>
                        <p>El botón “Aa” permite cambiar el tamaño de la letra en pantalla.</p>                                             
                    </Row>
                </Container>
            </Fragment>
    }
    ,[help])

    const openArt = (value) => {
        props.history.push('/art', { key: value, showInfo:true});
    }
        
    let artTypes = []; 
    const keys = Object.keys(ART_TYPES);

    for(let i = 0; i < keys.length; i++){
        let key = keys[i];
        artTypes[i] = 
            <Col className="horizontal-center" key={i}>
                <button className={`bigsvgbtn ${ART_TYPES[key].style}`} onClick={() => openArt(key)} type="button" aria-label={ART_TYPES[key].title}/> 
            </Col>;
    }

    const openAdditionalResources = () =>{
        props.history.push('/additionalResources',{showInfo:true})
    }

    return (
        <div role="application" aria-labelledby="galerias">
            <div className="right"><Image className='rightlogo' src={Guard} aria-hidden="true"/></div>
            <LeftContainer>
                <>
                    <Row className="row-full-width title horizontal-center">
                        <Image id="galerias" style={{width:'30vw'}} src={Title} alt="Galerias de arte"></Image>
                    </Row>
                    <Row className="row-full-width middle middle-full">
                        {artTypes}
                        <Col className="horizontal-center"> <button className="bigsvgbtn svgbtn-otros" onClick={openAdditionalResources} type="button" aria-label="Modelos del arte costarricense" /> </Col>
                    </Row>
                    <Row className="bottom-section">
                        <Col className="flex-begin"> <IconButton className='svgbtn fontSize-svg' color={Yellow} function={()=>{fontSizeModal.current.open()}} popover="Cambiar tamaño de letra" /> </Col>
                        <Col className="flex-end">  
                            <IconButton className='svgbtn help-svg' color={Yellow} function={()=>{toggleHelp(true)}} popover="Ayuda" />
                        </Col>
                    </Row>
                </>
            </LeftContainer>
            <HelpModal show={helpOpen} showModal={toggleHelp} content={help.current} descrBy="helpDesc"></HelpModal>
            <FontSizeModal ref={fontSizeModal}/>
        </div>
    );
}