import React, {useEffect,useState} from 'react';
import {
    Container
} from 'reactstrap';

import { useWindowDimensions } from '../utils/resizeHook';

export default function LeftContainer(props){

    const [width, setWidth] = useState('100vw');

    const windowSize = useWindowDimensions();

    useEffect(() => {
        setWidth((97 - (windowSize.height*96*(244/747))/windowSize.width) + "vw")
    }, [windowSize]);


    return(
        <Container className="left" style={{maxWidth:width}}>
            {props.children}
        </Container>
    )
}