import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {Blue} from '../constants';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from './iconButton.component';

const titleStyles = props => makeStyles((theme) => ({
    root: {
        marginBottom: '2%',
        position: 'absolute',
        top: props.rTop,
	    left: props.rLeft,
        maxWidth: '100vw',
        width: props.rWidth,
        height: "15%",
        padding: '0',
        display: 'flex',
        alignItems: 'center',

    },
    closeButton: {
        position: 'absolute',
        height: '10.6vh',
        width: '10.6vh',
	    top: props.cbTop,
	    left: props.cbLeft,
    },
}));

const CustomBlackdrop = withStyles((theme)=>({
    root:{
	    marginTop: '2vh',
	    marginLeft: '2vh',
        width:'calc(100vw - 4vh)',
        height:'96vh',  
    },
}))(Backdrop)

const DialogTitle = (props) => {
    const { children, onClose, closeButton, styleProps, ...other } = props;
    const classes = titleStyles(styleProps)();

    return (
        <>
        <IconButton className={`svgbtn ${classes.closeButton} close-svg`} color={Blue} function={onClose} popover={closeButton}/>
        <MuiDialogTitle disableTypography className={`${classes.root} big-font`} {...other}>
            {children}
        </MuiDialogTitle>
        </>
    );
};

const DialogContent = withStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '36%',
        left: '20%',
        width: '60%',
        height: '60%',
        lineHeight:'calc(3vh + 3vh * var(--font-zoom))',
        padding: '0 5% 0 0'
    },
}))(MuiDialogContent);

const dialogStyles = props => makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    paper:{
        color: 'white',
        position: 'absolute',
        backfaceVisibility: 'hidden',
        backgroundColor:'rgba(0,0,0,0.005)',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center',
        backgroundSize:'contain',
        backgroundImage:`url(${require("../resources/Popups/"+props.name).default})`,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -60%)',
        maxWidth: '100vw',
        width: props.width,
        height: props.height,
        boxShadow:"none !important",
        margin: 0
    },
}));

export default function InfoModal(props) {
    const bgPanel = props.bgPanel ?
        { name: "panel1.svg", width: 'calc(72vh*1.2846)', height: '72vh'} :
        { name: "panel4.svg", width: 'calc(70vh*1.82)', height: '70vh'};

    const title = props.bgPanel ?
        { cbTop: "20%", cbLeft: "7%", rTop: "21%", rLeft: "27%", rWidth: "42%" } :
        { cbTop: "12%", cbLeft: "6%", rTop: "14%", rLeft: "20%", rWidth: "60%"}

    const classesDialog = dialogStyles(bgPanel)();

    return(    
        <Dialog
            classes={classesDialog} 
            BackdropComponent={CustomBlackdrop}
            BackdropProps={props.hideBlackdrop===true?{style:{backgroundColor:'transparent'}}:{style:{backgroundColor:'rgba(0,0,0,0.9)'}}}
            id="customized-dialog"
            scroll="paper"
            open={props.show}
            disableRestoreFocus
            aria-labelledby={props.modalLabel?props.modalLabel:""}
            TransitionProps={{ onEnter: () => {
                if(props.roleDoc){
                    document.querySelector("#customized-dialog .MuiPaper-root").setAttribute("role", "document");
                } else {
                    document.querySelector("#customized-dialog .MuiPaper-root").setAttribute("role", "application");
                }
            }, onEntered: () => { 
                const focusable = document.getElementById("info-modal-content");
                if(focusable){
                    focusable.focus();
                }
            }}}
        > 
            <DialogTitle id="customized-dialog-title" onClose={() => props.showModal(false)} closeButton={props.closeButtonTitle} styleProps={title}>
                {props.title}
            </DialogTitle>
            <DialogContent className="info-font">
                {props.content}
            </DialogContent>
        </Dialog>
    );
}