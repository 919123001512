import React, { useEffect, useState, useRef, useCallback } from 'react';
import {Row, Col, Button} from 'reactstrap';
import Title from '../resources/Titulos/TitleModelosdeArte.svg';
import LeftContainer from './utils/leftContainer.component';
import Image from 'react-bootstrap/Image';
import Guard from '../resources/Guardas/guarda3.png';
import PhraseGuard from '../resources/Guardas/guarda2.png';
import Phrase from './phrase.component';
import './additionalResources.css';
import {Red,Blue,Yellow} from '../constants';
import IconButton from './iconButton.component';
import { isPlatform } from '@ionic/react';
import FontSizeModal from './fontSizeModal.component';

export default function AdditionalResources(props){

    const [showPhrase,setShowPhrase] = useState(false);
    const fontSizeModal = useRef();
    
    const refChange = useCallback((node) => {
        if(node){
            node.focus();
        }
    }, []);

    const goBack = ()=>{
        if(isPlatform("capacitor")){
            props.history.push('/scanner');
        }else{
            props.history.push('/menu');
        }
        
    }

    const openCredits=()=>{
        props.history.push('/credits');
    }

    useEffect(()=>{
        setShowPhrase(props.location.state.showInfo);

        return (() => {
            setShowPhrase(false);
        })
    },[props.location.state.showInfo])

    const downloadPDF=(value)=>{
        switch(value){
            case 0:
            default:{
                const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/modelo-arte-premoderno.pdf', '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
                break;
            }

            case 1:{
                const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/modelo-arte-moderno.pdf', '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
                break;
            }

            case 2:{   
                const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/modelo-arte-contemporaneo.pdf', '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
                break;
            }
        }
    }

    const downloadRA=()=>{
        const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/piramide-de-marcadores.pdf', '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const downloadManual=()=>{
        const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/manual-usuario-cac.pdf', '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const onPhraseEnd=()=>{
        setShowPhrase(false);
    }

    return(
        <>
        { showPhrase ? 
            <>
                <div className="right"><Image className='rightlogo2' src={PhraseGuard}/></div>
                <LeftContainer>
                    <Phrase onEnd={onPhraseEnd}></Phrase>
                </LeftContainer>
            </>
            :
            <div ref={refChange} tabIndex="-1" role="application" aria-labelledby="add-res-title" aria-describedby="add-res-desc">
                <div className="right"><Image className='rightlogo' src={Guard} aria-hidden="true"/></div>
                <LeftContainer>
                    <>
                        <Row  className="row-full-width title horizontal-center">
                            <Image id="add-res-title" style={{width:'30vw'}} src={Title} alt="Modelos del arte costarricense"></Image>
                        </Row>
                        <Row className="middle middle-full">
                            <Row id="add-res-desc">
                            <p className="aditional-paragraph-text">Aprendé y reflexioná acerca de los Modelos de Arte Costarricense, para ello oprimí alguno de los siguientes enlaces.</p>
                            </Row>
                            <Row className="row-full-width">
                                <Col className="text-start"> <Button className="bigsvgbtn svgbtn-model-premod" onClick={()=>{downloadPDF(0)}} type="button"> <span className="screen-reader-text"> Modelo Pre Moderno </span> </Button> </Col>
                                <Col className="text-center"> <Button className="bigsvgbtn svgbtn-model-moder" onClick={()=>{downloadPDF(1)}} type="button"> <span className="screen-reader-text"> Modelo Moderno </span> </Button> </Col>
                                <Col className="text-end"> <Button className="bigsvgbtn svgbtn-model-conte" onClick={()=>{downloadPDF(2)}} type="button"> <span className="screen-reader-text"> Modelo Contemporáneo </span> </Button> </Col>
                            </Row>
                        </Row>
                        <Row className="bottom-section">
                            <Col className="flex-begin"> 
                                <IconButton className={isPlatform("capacitor")?'svgbtn camera-svg':'svgbtn galery-svg'} color={Blue} function={goBack} popover="Galerías" />
                                <IconButton className='svgbtn ra-svg' color={Red} function={downloadRA} popover="Marcador de realidad aumentada" />
                                <Button className="svgbtn svgbtn-manual" onClick={downloadManual} type="button"> <span className="screen-reader-text"> Manual de usuario </span> </Button>
                                <Button className="svgbtn svgbtn-credits" onClick={openCredits} type="button"> <span className="screen-reader-text"> Créditos </span> </Button>
                            </Col>
                            <Col className="flex-end"> 
                                <IconButton className='svgbtn fontSize-svg' color={Yellow} function={()=>{fontSizeModal.current.open()}} popover="Cambiar tamaño de letra" /> 
                            </Col>
                        </Row>
                    </>
                </LeftContainer>
                <FontSizeModal ref={fontSizeModal}/>
            </div>
        }
        </>
    )
}