export const importScript = (id, resourceURL,onLoad) =>{
    const existingscript = document.getElementById(id);
    if (!existingscript) {  
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.id = id;
        script.src = resourceURL;
        script.async = true;
        script.onload = onLoad;
        document.body.appendChild(script);
    }else{
        onLoad();
    }
}

export const removeScript = (scriptToremove) => {
    const existingscript = document.getElementById(scriptToremove);
    if(existingscript){
        document.body.removeChild(existingscript)
    }
}