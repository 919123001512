import React from 'react';
import PropTypes from 'prop-types';
import Gif from '../../resources/loader.gif';

Loader.propTypes = {
    id: PropTypes.string.isRequired
};
  
export function Loader(props){
    return (
        <div id={props.id} style={{position:"fixed", display:'flex', flexDirection:'column'}} className="vertical-center-max horizontal-center-max">
            <img src={Gif} alt='Cargando contenido, por favor esperá'/>
            { props.label ? <p className="loader-label"> {props.label} </p> : <></>}
            <span className="big-font" aria-hidden>Cargando contenido, por favor esperá.</span>
        </div>
    )
}
