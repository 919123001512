import {axios} from '../../Axios';

import {
    QUESTIONS_SAVED,
    ERROR,
} from '../types';

export const setParticipante = ({nivel,tipo_usuario,tipo_institucion,uuid}) => (dispatch) => {
    const body = {nivel: nivel, tipo_usuario: tipo_usuario, tipo_institucion: tipo_institucion, uuid:uuid};
    return new Promise((resolve,reject) => {
        axios.post('participantes/register', body)
            .then(
                (res) =>{
                    if(res.data.status !== true){
                        dispatch({
                            type: ERROR,
                            payload: res.data.message
                        });

                        return reject(res.data.message)                    
                    }else{
                        dispatch({
                            type: QUESTIONS_SAVED,
                            payload:res.data.message,
                            uuid:uuid
                        });
                        return resolve(res.data.message)
                    }
                }
            )
            .catch(
                (err) => {
                    if(err.response && err.response.data){
                        return reject(err.response.data)
                    }else{
                        return reject({error:true, message:err.message});
                    }
                }
            )
    });
}