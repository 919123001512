import React, { useRef,useMemo, useState, Fragment} from 'react';
import {Row, Col, Container} from 'reactstrap';
import Title from '../resources/Titulos/ARtittle.svg';
import LeftContainer from './utils/leftContainer.component';
import Image from 'react-bootstrap/Image';
import Guard from '../resources/Guardas/guarda2.png';
import {Red,Blue, White, Yellow} from '../constants';
import IconButton from './iconButton.component';
import HelpModal from './helpModal.component';
import './menu-ar.css';
import { SocialSharing } from '@ionic-native/social-sharing';
import { isPlatform } from '@ionic/react';
import FontSizeModal from './fontSizeModal.component';

export default function MenuAR(props){

    const [helpOpen, toggleHelp] = useState(false);
    const help = useRef();
    const fontSizeModal = useRef();
    
    useMemo(()=>{
        help.current = 
            <Fragment>
                <Container id="helpDesc" className="center-object" style={{maxWidth:'100%', flexFlow:'wrap'}}>
                    <Row style={{minWidth:'100%'}}>
                        <p>Presioná el botón “escanear” (cámara), para abrir la cámara del dispositivo y escáner un marcador. </p>                       
                        <p>Recuerda; cuando escaneés un marcador, hacelo en un lugar bien iluminado.</p>
                        <p>Descargá la Pirámide de marcadores oprimiendo el botón “descargar” (flecha hacia abajo).</p>
                        <p>Si deseas compartir el documento presioná el botón con el icono de compartir.</p>
                    </Row>
                </Container>
            </Fragment>
    }
    ,[help])
    
    const goScanner = ()=>{
        props.history.push('/scanner');
    }

    const downloadPDF=(value)=>{
        switch(value){
            case 0:
            default:{
                const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/modelo-arte-premoderno.pdf', '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
                break;
            }

            case 1:{
                const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/modelo-arte-moderno.pdf', '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
                break;
            }

            case 2:{   
                const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/modelo-arte-contemporaneo.pdf', '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
                break;
            }
        }

    }

    const downloadRA=()=>{
        const newWindow = window.open('https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/piramide-de-marcadores.pdf', '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const Share=()=>{
        if(!isPlatform("capacitor")){
            return;
        }

        var options = {
            message: 'Pirámide de marcadores Arte MEP',
            subject: 'Pirámide de marcadores Arte MEP',
            files: ['https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/piramide-de-marcadores.pdf'],
            url: 'https://recursos.mep.go.cr/2021/claves-del-arte-cost/pdf/piramide-de-marcadores.pdf'
        };
        
        SocialSharing.shareWithOptions(options).then(() => {
            console.log("Sharing Success");
        }).catch((err) => {
            alert("Error al compartir: "+err);
        });        
    }

    return(
        <div role="application" aria-describedby="galerias">
            <div className="right"><Image className='rightlogo2' src={Guard} aria-hidden="true"/></div>
            <LeftContainer>
                <>
                    <Row  className="row-full-width title horizontal-center">
                        <Image style={{width:'30vw'}} src={Title} alt="Marcadores de realidad aumentada"></Image>
                    </Row>
                    <Row className="middle middle-full" style={{"width":"100%"}}>
                        <div className="pyramid-container">
                        </div>
                        <div className="menu-ar-text" id="galerias">
                            <p className="aditional-paragraph-text">Disfrutá una muestra de la riqueza artística costarricense ingresando a cada una de las galerías de obras en 3D.</p>
                            <br aria-hidden="true"/>
                            <p className="aditional-paragraph-text">Para visualizar las galerías descargá, imprimí y armá la pirámide de marcadores.</p>
                            <br aria-hidden="true"/>
                            <p className="aditional-paragraph-text">Cada cara de la pirámide posee un marcador, escanéalo con tú cámara y la aplicación te llevará a la galería elegida.</p>
                        </div>
                    </Row>
                    <Row className="bottom-section">
                        <Col className="flex-begin"> 
                            <IconButton className='svgbtn fontSize-svg' color={Yellow} function={()=>{fontSizeModal.current.open()}} popover="Cambiar tamaño de letra" /> 
                        </Col>
                        <Col className="flex-end"> 
                            <IconButton className='svgbtn help-svg' color={Red} function={()=>{toggleHelp(true)}} popover="Ayuda" />
                            <IconButton className='svgbtn share-svg' color={Blue} function={Share} popover="Compartir" />
                            <IconButton className='svgbtn download-svg' color={White} function={downloadRA} popover="Descargar" />
                            <IconButton className='svgbtn camera-svg' color={Yellow} function={goScanner} popover="Escaneá" />
                        </Col>
                    </Row>
                </>
            </LeftContainer>
            <HelpModal show={helpOpen} showModal={toggleHelp} content={help.current} descrBy="helpDesc"></HelpModal>
            <FontSizeModal ref={fontSizeModal}/>
        </div>
    )
}