import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import ReactDOM from "react-dom";
import {Blue} from '../constants';
import FocusTrap  from "focus-trap-react";
import './fontSizeModal.css';
import IconButton from './iconButton.component';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const marks = [{value: -25, label: 'Pequeño'}, {value: 0, label: 'Normal'}, {value: 25, label: 'Grande'}];

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "5%",
        marginBottom: "5vh",
        color: "white",
        width: "80%",
        marginRight: "10%",
        marginLeft: "10%"
    },
    mark: {
        color: "white",
        fontWeight: "400",
        fontSize: "2.5vh",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1rem",
            lineHeight: "1rem"
        },
    },
    typo: {
        position: "absolute",
        fontWeight: 400,
        fontSize: "calc(2.5vh + 2.5vh * var(--font-zoom))",
        color: "white",
        top: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: "calc(1rem + 1rem * var(--font-zoom))",
        },
    }
}));

const Modal = (props, ref) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);

    useEffect(() => {
        let value = getComputedStyle(document.documentElement).getPropertyValue('--font-zoom');
        setSliderValue(parseInt(value * 100));
    },[]);

    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false)
    }));

    const changeFont = (value) => {
        setSliderValue(value);
        document.documentElement.style.setProperty('--font-zoom', value/100);
    }

    const getAriaValueText = (value) => {
        for(let i = 0; i < marks.length; i++){
            if(marks[i].value === value){
                return marks[i].label;
            }
        }
    }

    return ReactDOM.createPortal(
        <FocusTrap active={isOpen} focusTrapOptions={{ initialFocus: "#font-size-modal", returnFocusOnDeactivate: false }}> 
            <div id="font-size-modal" className={isOpen?"show":"hide"} tabIndex="-1" role="dialog" aria-label="Cambio de Tamaño de letra.">
                <div className="pop-up-panel-fontSize">
                    <IconButton className="svgbtn pop-up-panel-fontSize-close close-svg" color={Blue} function={() =>{ document.activeElement.blur(); setIsOpen(false);}} popover='Cerrar cambio de tamaño de letra.'/>
                    <div className="pop-up-panel-fontSize-content small-font">
                        <div className="font-size-slider">
                            <Typography id="discrete-slider" classes={{ root: classes.typo }}> Cambiar tamaño de letra </Typography>
                            <Slider getAriaValueText={getAriaValueText} classes={{root: classes.root, markLabel: classes.mark}} onChange={(e, value) => changeFont(value)}
                                value={sliderValue} aria-labelledby="discrete-slider" step={25} marks={marks} min={-25} max={25} />
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>,
        document.querySelector("#font-size-modal-root")
    );
};

export default forwardRef(Modal);

