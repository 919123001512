import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import LeftContainer from './utils/leftContainer.component';
import Image from 'react-bootstrap/Image';
import Guard from '../resources/Guardas/guarda3.png';
import {Red} from '../constants';
import IconButton from './iconButton.component';
import {Row} from 'reactstrap';
import FocusTrap from 'focus-trap-react';

function ArtInfo(props) {
    const[description, setDescription] = useState();
    const artDescriptions = useRef();
    const[title,setTitle] = useState();
    const[alt,setAlt] = useState();

    useMemo(()=>{
        artDescriptions.current = {
            ArtePreModerno :
                <Fragment>
                    <p className="question-paragraph-text">Incluye todas aquellas manifestaciones de las artes plásticas, diseño y arquitectura que fueron creadas antes de los movimientos modernos de vanguardia del siglo XX.</p>
                    <div className="question-paragraph-text"><p>En Costa Rica, comprende las expresiones culturales:</p>
                        <ul>
                            <li>precolombinas; creadas bajo creencias mágico-religiosas, </li>
                            <li>coloniales; limitadas al culto de lo sagrado y divino (religiosidad), </li>
                            <li>los primeros años de la república hasta iniciado el siglo XX, donde se imponen las normas técnicas, modelos y patrones del academicismo.</li>
                        </ul> 
                    </div>
                    <p className="question-paragraph-text">Por lo tanto, el arte pre moderno, se basa en la observación de la naturaleza y exaltación de la belleza inspirada por la naturaleza. Bajo una vocación académica; busca la mímesis como proceso para captar la apariencia de las cosas en el mundo (visual), pero también lo ornamental, la simetría, la proporción, la unidad, la percepción. </p>
                    <p className="question-paragraph-text">Sus representaciones plásticas se dan de afuera hacia adentro, es decir, de lo objetivo a la interioridad de la expresión artística.</p>					
                    <p className="question-paragraph-text">(Adaptación de texto. Plan de estudios, III Ciclo y Educación Diversificada, MEP, 2009)</p>
                </Fragment>,
            ArteModerno:
                <Fragment>
                    <p className="question-paragraph-text">Es importante considerar que el concepto de arte moderno costarricense no es cronológico, es decir, no se limita por periodos de tiempo, aunque para efectos de su comprensión se puede decir que abarca la mayor parte de la producción artística de finales del siglo XIX (coincidiendo con la producción final del pre moderno), hasta aproximadamente los años 1970, aunque posterior a esta fecha aún es posible encontrar una producción plástica bajo sus características.</p>
                    <p className="question-paragraph-text">Básicamente, se opone al arte académico al buscar en la experimentación y la innovación la fórmula para la creación artística y no bajo patrones o modelos establecidos.  Además, rompe con la necesidad de representar el mundo tal como se ve, ya que es un arte que busca autonomía para crear su propia realidad lejos de una representación literal de las cosas.</p>
                    <p className="question-paragraph-text">Por lo tanto, es una nueva forma de entender la estética, la teoría y la función del arte, en la que el valor dominante es la experimentación con nuevos puntos de vista, con nuevas ideas sobre la naturaleza, materiales y funciones artísticas, incursionando en niveles abstractos, orgánicos, geométricos, vanguardistas, funcionales, expresivos.</p>
                    <p className="question-paragraph-text">El arte moderno trasciende la apariencia de las cosas, los objetos, la naturaleza al integrar el sentimiento humano a la imagen, es decir, la práctica artística se orienta de adentro hacia afuera; lo que se siente no lo que se ve.</p>
                    <p className="question-paragraph-text">(Adaptación de texto. Plan de estudios, III Ciclo y Educación Diversificada, MEP, 2009)</p>
                </Fragment>,

            ArteContemporaneo:
                <Fragment>
                    <p className="question-paragraph-text">El término arte contemporáneo en Costa Rica empieza a utilizarse después de 1970 refiriéndose a los movimientos artísticos que se generan después de los movimientos modernistas y oponiéndose a ellos. </p>
                    <p className="question-paragraph-text">Se caracteriza porque pone en crisis el objeto artístico tradicional al salirse hacia el espacio, es decir no lo encajona en un marco, en un material, en un espacio concreto-definido, hace que se relacione e intervenga con el resto de los objetos, con el observador, con el medio en rededor y quizá mucho más allá. Esto, a través de acciones creativas alternativas, experimentales y no tan formales, donde se incluye desde la pintura de caballete, la instalación, el uso de las nuevas tecnologías, recursos y materiales diversos hasta expresiones individuales y sociales.</p>
                    <p className="question-paragraph-text">Posee un lenguaje más conceptual e involucra una actividad intelectual que supera la técnica, en función de la idea, es decir su objetivo es generar pensamiento (reflexión, emoción, reacción).</p>			
                    <p className="question-paragraph-text">El arte contemporáneo busca ampliar el concepto de arte y establecerlo como un acto comunicativo, entre el artista, la obra, el observador, el medio, etc. </p>	
                    <p className="question-paragraph-text">(Adaptación de texto. Plan de estudios, III Ciclo y Educación Diversificada, MEP, 2009)</p>	
                </Fragment>,
        }
    },[artDescriptions]);


    useEffect(() => {
        if(props.show){
            document.getElementById("art-info-modal").classList.remove("hide");
        } else{
            document.getElementById("art-info-modal").classList.add("hide");
        }

        switch(props.art){
            default:
            case "arte-premoderno":
                setDescription(artDescriptions.current.ArtePreModerno);
                setTitle(require('../resources/Titulos/TitleConceptoPREMODERNO.svg').default);
                setAlt("Concepto de arte premoderno en la historia costarricense");
                break;
            case "arte-moderno":
                setDescription(artDescriptions.current.ArteModerno);
                setTitle(require('../resources/Titulos/TitleConceptoMODERNO.svg').default);
                setAlt("Concepto de arte moderno en la historia costarricense");
                break;
            case "arte-contemporaneo":
                setDescription(artDescriptions.current.ArteContemporaneo);
                setTitle(require('../resources/Titulos/TitleConceptoCONTEMPORANEO.svg').default);
                setAlt("Concepto de arte contemporáneo en la historia costarricense");
                break;
        }

    },[props.show, props.art]);

    return(
        <FocusTrap active={props.show} focusTrapOptions={{ returnFocusOnDeactivate: false, fallbackFocus:"#art-info-modal", initialFocus: "#art-info-modal"}}>
            <div id="art-info-modal" className="hide" role="dialog" aria-modal="true" tabIndex="-1" aria-describedby="art-info-description">
                <div className="right"><Image className='rightlogo' src={Guard} aria-hidden="true"/></div>
                <LeftContainer>
                    <Row className="row-full-width title">
                        <img style={{width:'100%'}} src={title} alt={alt} />
                    </Row>
                    <Row className="row-full-width middle" style={{display:'block'}}>
                        <div id="art-info-description">
                            {description}
                        </div>
                    </Row>
                    <Row className="bottom-section">                      
                        <IconButton className='svgbtn close-svg' color={Red} function={()=>{ document.activeElement.blur(); props.showModal(false);}} popover="Cerrar"/>
                    </Row>
                </LeftContainer>
            </div>
        </FocusTrap>
    );
}

export default (ArtInfo)
