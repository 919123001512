import React from 'react';
import {Row, Col, Container } from 'react-bootstrap';
import {ReactComponent as Palet} from '../../resources/Iconos/COLORPALET.svg';
import {ReactComponent as Info} from '../../resources/Iconos/CONTENT.svg';
import {ReactComponent as Font} from '../../resources/Iconos/FONTRESIZE.svg';
import moveHelpMouse from '../../resources/CTRLMove.png';
import rotateHelpMouse from '../../resources/RotateClick.png';
import moveHelpTouch from '../../resources/PhoneMove.png';
import rotateHelpTouch from '../../resources/PhoneRotate.png';
import zoomMouse from '../../resources/ScrollZoom.png';
import zoomPhone from '../../resources/PhoneZoom.png';
import { isPlatform } from '@ionic/react';
import {Blue,Yellow} from '../../constants';
import HelpModal from '../helpModal.component';

export default function ViewerHelp(props) {   
    return(    
        <HelpModal show={props.show} showModal={props.showModal} descrBy="helpDesc" content={
            <Container style={{maxWidth:'100%'}}>
                <p id="helpDesc" hidden> Navegá la ayuda para entender cómo interactuar con el visualizador. </p>
                <Row className="row-space">
                    <Col xs={4} className="col-fit"> <Info tabIndex="0" aria-label="Botón Ficha de la obra." role="figure" aria-describedby="fichaDesc" className="svgbtn" fill={Yellow}/></Col>
                    <Col xs={8} id="fichaDesc" className=""> <p>Presioná este icono para ingresar a la ficha técnica de la obra.</p> </Col>
                </Row>
                <Row className="row-space">
                    <Col xs={4} className="col-fit"> <Palet tabIndex="0" aria-label="Botón Selector de color de fondo." role="figure" aria-describedby="colorDesc" className="svgbtn" fill={Blue}/></Col>
                    <Col xs={8} id="colorDesc" className=""> <p>Presioná este icono para abrir el selector de color de fondo.</p> </Col>
                </Row>
                <Row className="row-space">
                    <Col xs={4} className="col-fit"> <Font tabIndex="0" aria-label="Botón Cambiar tamaño de letra." role="figure" aria-describedby="fontDesc" className="svgbtn" fill={Yellow}/></Col>
                    <Col xs={8} id="fontDesc" className=""> <p>Presioná este icono para aumentar o disminuir el tamaño de las letras en pantalla.</p> </Col>
                </Row>
                <Row className="row-space">
                    <Col xs={4} className="col-fit"> 
                    {isPlatform("capacitor") ? <div style={{backgroundImage:`url(${moveHelpTouch})`}} className="svgbtn" fill={Blue}/> :
                        <div tabIndex="0" aria-label="Movimiento con el ratón." role="figure" aria-describedby="moveMouse" style={{backgroundImage:`url(${moveHelpMouse})`}} className="svgbtn" fill={Blue}/>}
                    </Col>
                    <Col xs={8} className="">
                    {isPlatform("capacitor") ? 
                        <><p>Para trasladar o mover la imagen 3D, tocá con dos dedos la pantalla y arrastrá la imagen.</p></> :
                        <><p id="moveMouse">Para trasladar o mover la imagen 3D, presioná la tecla CTRL, mientras presionas el botón izquierdo del ratón y mové el ratón.</p></>
                    }
                    </Col>
                </Row>
                <Row className="row-space">
                    <Col xs={4} className="col-fit">
                    {isPlatform("capacitor") ? <div style={{backgroundImage:`url(${rotateHelpTouch})`}} className="svgbtn" fill={Blue}/> :
                        <div tabIndex="0" aria-label="Rotación con el ratón." role="figure" aria-describedby="rotateMouse" style={{backgroundImage:`url(${rotateHelpMouse})`}} className="svgbtn" fill={Blue}/>}
                    </Col>
                    <Col xs={8} className="">
                    {isPlatform("capacitor") ? 
                        <><p>Para rotar la imagen 3D, tocá con un dedo la pantalla y arrastrá la imagen.</p></> :
                        <><p id="rotateMouse">Para rotar la imagen 3D, mantené presionado el botón izquierdo del ratón y mové el ratón. </p></>
                    }
                    </Col>
                </Row>
                <Row className="row-space">
                    <Col xs={4} className="col-fit">
                    {isPlatform("capacitor") ? <div style={{backgroundImage:`url(${zoomPhone})`}} className="svgbtn" fill={Blue}/> :
                        <div tabIndex="0" aria-label="Acercamiento con el ratón." role="figure" aria-describedby="zoomMouse" style={{backgroundImage:`url(${zoomMouse})`}} className="svgbtn" fill={Blue}/>}
                    </Col>
                    <Col xs={8} className="">
                    {isPlatform("capacitor") ? 
                        <><p>Para acercar el modelo toca con dos dedos y expande. Para alejar realiza lo contrario.</p></> :
                        <><p id="zoomMouse">Para acercar o alejar la imagen 3D, mové la rueda del ratón.</p></>
                    }
                    </Col>
                </Row>
            </Container>}>
        </HelpModal>
    );
}