import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './redux';
import thunk from 'redux-thunk';


const persistedReducer = persistReducer({ key: 'root', storage, blacklist: ['filter', 'modals'] }, reducers)
 
function configureStore (initialState) {
  const enhancer = compose(
    applyMiddleware(thunk)
  )
  return createStore(persistedReducer, initialState, enhancer)
}
 
const initialState = {}
export const store = configureStore(initialState)
export const persistor = persistStore(store)