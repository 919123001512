import React, {Component} from 'react';

export default class ProgressRing extends Component {
    constructor(props) {
        super(props);
        
        const { radius, stroke } = this.props;
        
        this.normalizedRadius = radius - stroke * 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;

    }
    
    render() {
        const { radius, stroke, progress } = this.props;
        const strokeDashoffset = this.circumference - progress / 100 * this.circumference;
    
        return (
            <div className="progress-ring">
                <svg height="100%" viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
                    <circle
                        stroke="white"
                        fill="transparent"
                        strokeWidth={ stroke }
                        strokeDasharray={this.circumference + ' ' + this.circumference}
                        style={ { strokeDashoffset } }
                        r={ this.normalizedRadius }
                        cx={ radius }
                        cy={ radius }
                    />
                </svg>
                <div className="progress-ring-label"> {progress+"%"} </div>
            </div>
        );
    }
}
