import React, { useEffect, useRef, useState, useMemo, Fragment } from 'react';
import { importScript, removeScript } from './utils/importScript';
import { Loader } from './utils/loader.component';
import { useWindowDimensions } from './utils/resizeHook';
import { Container, Row, Col } from 'reactstrap';
import { ART_TYPES } from '../constants';
import HelpModal from './helpModal.component';
import IconButton from './iconButton.component';
import { Red, Yellow } from '../constants';
import { iosrtc } from '@ionic-native/iosrtc';
import { isPlatform } from '@ionic/core';
//const openCV = "https://docs.opencv.org/4.5.2/opencv.js";
const markerPaths = ["markers/4.png", "markers/2.png", "markers/1.png", "markers/3.png"];
const loaderID = "loader_scanner";

function Scanner(props) {
    const [cv, setCV] = useState();
    const video = useRef();
    const outputCanvas = useRef();
    const markerRef = useRef([]);

    const marker = useRef(null);
    const markerKeyPoints = useRef(null);
    const markerDescriptors = useRef(null);

    const useTemplate = useRef(false);

    const [helpOpen, toggleHelp] = useState(false);
    const help = useRef();

    const windowSize = useWindowDimensions();

    useMemo(() => {
        help.current =
            <Fragment>
                <Container id="helpDesc" className="center-object" style={{ maxWidth: '100%' }}>
                    <Row className="center-object">
                        <p>Al encender la cámara enfocá uno de los marcadores en las caras de la pirámide, automáticamente se abrirá la galería seleccionada.</p>
                        <p>Recuerda; cuando escaneés un marcador, hacelo en un lugar bien iluminado.</p>
                    </Row>
                </Container>
            </Fragment>
    }, [help])

    const markers = useRef(markerPaths.map((value, index) => {
        return (
            <canvas hidden ref={(element) => { markerRef.current[index] = element }} id={"canvas" + index} key={"canvas" + index} width="256" height="256"></canvas>
        )
    }))

    useEffect(() => {
        if (markerRef.current.length > 0) {
            markerPaths.map((path, index) => {
                let ctx = markerRef.current[index].getContext('2d');
                let img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = () => {
                    let scale = Math.min(ctx.canvas.width / img.width, ctx.canvas.height / img.height);
                    // get the top left position of the image
                    let x = (ctx.canvas.width / 2) - (img.width / 2) * scale;
                    let y = (ctx.canvas.height / 2) - (img.height / 2) * scale;
                    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
                }
                img.onerror = function () {
                    console.log("ERROR");
                }
                img.src = path;
                return img;
            })
        }
    }, []);


    useEffect(() => {
        let processVideoT;
        let compareFrameT;
        var detector;
        var matcher;
        let src;
        let cap;
        const FPS = 30;
        const CompareFPS = 3;

        const goArt = (value) => {
            if (value < 3) {
                props.history.push("/art", { key: Object.keys(ART_TYPES)[value], showInfo: false });
            }
            else if (value === 3) {
                props.history.push('/additionalResources', { showInfo: true })
            }

        };

        function processVideo() {
            if (!outputCanvas.current) {
                return;
            }

            try {
                cap.read(src);
                cv.imshow('canvasOutput', src);
            } catch (err) {
                console.log(err);
            }
        };

        function Template(src, index) {
            let result = false;

            for (let i = 0; i < markerPaths.length; ++i) {
                let dst = new cv.Mat();
                let mask = new cv.Mat();
                cv.matchTemplate(src, marker.current[i], dst, cv.TM_CCOEFF_NORMED, mask);
                let result = cv.minMaxLoc(dst, mask);

                if (result.maxVal + Math.abs(result.minVal) > 0.75) {
                    result = true
                }

                dst.delete();
                mask.delete();
                result.delete();

                if (result) {
                    index(i);
                    break;
                }
            }

            //See the marker
            /*let maxPoint = result.maxLoc;
            let color = new cv.Scalar(255, 0, 0, 255);
            let point = new cv.Point(maxPoint.x + templ.cols, maxPoint.y + templ.rows);
            cv.rectangle(src, maxPoint, point, color, 2, cv.LINE_8, 0);
            cv.imshow('canvasOutput3', src);*/

            return result;
        }

        function MatchFeature(src, index) {
            let keypoints1 = new cv.KeyPointVector();
            let descriptors1 = new cv.Mat();
            detector.detectAndCompute(src, new cv.Mat(), keypoints1, descriptors1);

            let result = false;

            for (let i = 0; i < markerPaths.length; ++i) {
                let matches = new cv.DMatchVector();
                let good_matches = new cv.DMatchVector();

                matcher.match(descriptors1, markerDescriptors.current[i], matches);

                for (let i = 0; i < matches.size(); i++) {
                    if (matches.get(i).distance < 30) {
                        good_matches.push_back(matches.get(i));
                    }
                }

                //See the marker
                /*let imMatches = new cv.Mat();
                let color = new cv.Scalar(0,255,0, 255);
                cv.drawMatches(src, keypoints1, marker.current[i], markerKeyPoints.current[i], good_matches, imMatches, color);
                cv.imshow('canvasOutput3', imMatches);
                imMatches.delete();
                console.log("Matches: "+good_matches.size());*/

                result |= good_matches.size() > 10;

                matches.delete();
                good_matches.delete();

                if (result) {
                    index(i);
                    break;
                }
            }

            keypoints1.delete();
            descriptors1.delete();
            return result;
        }

        function Compare() {
            //let begin = Date.now();
            if (!outputCanvas.current) {
                return;
            }

            if (outputCanvas.current.hidden) {
                processVideo();
            }

            let src = cv.imread('canvasOutput', cv.IMREAD_GRAYSCALE);

            if (!marker.current) {
                marker.current = [];
                markerKeyPoints.current = [];
                markerDescriptors.current = [];

                for (let i = 0; i < markerPaths.length; ++i) {
                    marker.current[i] = cv.imread("canvas" + i, cv.IMREAD_GRAYSCALE);

                    if (!useTemplate.current) {
                        markerKeyPoints.current[i] = new cv.KeyPointVector();
                        markerDescriptors.current[i] = new cv.Mat();

                        detector.detectAndCompute(marker.current[i], new cv.Mat(), markerKeyPoints.current[i], markerDescriptors.current[i]);
                    }
                }
            }

            if (!src.empty() && marker.current.length > 0) {
                let result;
                if (useTemplate.current) {
                    if (Template(src, (value) => { result = value })) {
                        goArt(result)
                    }
                } else {
                    if (MatchFeature(src, (value) => { result = value })) {
                        //console.log("resultado "+result);
                        goArt(result)
                    }
                }
            } else {
                console.log("not images to compare");
            }


            src.delete();
            //let delay = 1000/CompareFPS/* - (Date.now() - begin)*/;
            //compareFrameT=setTimeout(Compare, delay);
        }

        function Init() {
            src = new cv.Mat(video.current.height, video.current.width, cv.CV_8UC4);
            //let dst = new cv.Mat(video.current.height, video.current.width, cv.CV_8UC1);
            cap = new cv.VideoCapture(video.current);


            if (!useTemplate.current) {
                detector = new cv.ORB();
                matcher = new cv.BFMatcher(cv.NORM_HAMMING, true); //cv.NORM_HAMMING, true
            }

            // schedule the first one.
            if (!outputCanvas.current.hidden) {
                console.log("process video is been called");
                processVideoT = setInterval(processVideo, 1000 / FPS); //ONLY IF THE USER CAN SEE CANVAS
            }
            compareFrameT = setInterval(Compare, 1000 / CompareFPS);
        };

        if (cv) {
            if (cv.getBuildInformation){
                console.log("ALREADY INIT");
                Init();
            }
            else {
                cv['onRuntimeInitialized']=()=>{
                    console.log("INIT");
                    Init();
                }
            }

            if(isPlatform('ios')){
              document.body.style.backgroundColor = 'transparent';
            }
        }

        return function cleanUp() {
            clearInterval(processVideoT);
            clearInterval(compareFrameT);
            document.body.style.backgroundColor = '';
        }

    }, [cv, props]);

    useEffect(() => {
        let mstream = null;

        let width = windowSize.width * 98 / 100;
        let height = windowSize.height * 96 / 100;

        video.current.width = width;
        video.current.height = height;

        const onLoad = () => {

            let videoConfig = {
                width: { min: 640, ideal: 1920, max: 1920 },
                height: { min: 400, ideal: 1080 },
                aspectRatio: 1.777777778,
                facingMode: { ideal: "environment" }
            };

            if(isPlatform("ios")) {
                iosrtc.registerGlobals()
                    .then(() => console.log("capture started"))
                    .catch((error) => console.log("ERROR: " + error));

                videoConfig['deviceId'] = { exact: 'com.apple.avfoundation.avcapturedevice.built-in_video:0' };
            } else {
                video.current.classList.remove('camera');
            }

            navigator.mediaDevices.getUserMedia({
                video: videoConfig,
                audio: false
            }).then(
                function (stream) {
                    mstream = stream;
                    video.current.srcObject = mstream;
                    video.current.play();
                    setCV(window.cv);
                    document.getElementById(loaderID).style.display = 'none';

                    return mstream;
                }
            ).catch(
                function (err) {
                    console.log("An error occurred! " + err);
                    props.history.push("/forcedMenu", {});
                }
            );
        }

        importScript("openCV", process.env.PUBLIC_URL + "/opencv.js", onLoad);

        return function cleanUp() {
            //removeScript("openCV");
            if (mstream) {
                let tracks = mstream.getTracks();
                console.log(tracks);
                for (let i = 0; i < tracks.length; ++i) {
                    tracks[i].stop();
                }
            }
        }
    }, [windowSize, props])

    const goMenu = () => {
        props.history.push("/menu");
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader id={loaderID} />
            <video ref={video} style={{ display: cv ? "block" : "none" }} className="camera" id="videoInput" alt="Cámara"/>
            <canvas hidden ref={outputCanvas} id="canvasOutput"></canvas>
            {/*<canvas id="canvasOutput3"></canvas>*/}

            {markers.current}

            <div className="bottom-section">
                <IconButton className='svgbtn back-svg' color={Red} function={goMenu} popover="Atrás" />
                <IconButton className='svgbtn help-svg' color={Yellow} function={() => { toggleHelp(true) }} popover="Ayuda" />
            </div>
            <div style={{ position: 'absolute', top: 0, left: 0, height: '96vh' }}>
                <HelpModal show={helpOpen} showModal={toggleHelp} content={help.current} descrBy="helpDesc"></HelpModal>
            </div>

        </div>
    );
}

export default (Scanner);
