import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Loader} from './utils/loader.component'
import axios from 'axios';
import './introduction.css';
import {Container, Row, Col} from 'reactstrap';
import {Yellow} from '../constants';
import IconButton from './iconButton.component';
import FontSizeModal from './fontSizeModal.component';

var XMLParser = require('react-xml-parser');

export default function Phrase({onEnd}) {
    const [phrase,setPhrase] = useState("");
    const [author,setAuthor] = useState("");
    const [paragraphs,setParagraphs] = useState([]);    
    const [audio,setAudio] = useState();
    const [isLoading,setLoading] = useState(true);
    const fontSizeModal = useRef();
    const audioRef = useRef();

    const goNext= ()=>{
        if(audioRef.current){
            audioRef.current.pause();
        }
        onEnd();
    }

    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    useEffect( ()=>{      
        axios.get(process.env.PUBLIC_URL + '/phrases-list.xml', {
            "Content-Type": "application/xml; charset=utf-8"
        })
        .then((response) => {          
            var xml = new XMLParser().parseFromString(response.data);
            var entries = xml.getElementsByTagName('Entry');
            const index = getRandomInt(0, entries.length);
            
            setPhrase(entries[index].getElementsByTagName('Text')[0].value);
            setAuthor(entries[index].getElementsByTagName('Author')[0].value);
            setAudio(entries[index].getElementsByTagName('Audio')[0].value);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error.message);
        })        
    },[]);

    useEffect(()=>{
        let lines = phrase.split('<br/>');
        let p = [];
        for(let i = 0; i < lines.length; i++){           
            p[i] = <p key={i.toString()}> {lines[i]} </p>
        }

        setParagraphs(p);
    },[phrase]);
    

    useEffect(() => {
        if(audioRef.current){
            audioRef.current.focus();
            setTimeout(() => {
                audioRef.current.play().then(()=>{
                    console.log("playing audio");
                }).catch(error => {         
                    if (error.name === "NotAllowedError") {                   
                        audioRef.current.controls = true;        
                    } else {
                        //console.log("Error playing audio: "+error);
                    }
                });
            }, 250);
        }
    }, [isLoading]);

    if(isLoading){
        <Loader id="LoaderIntro"></Loader>
    }

    const refChange = useCallback((node) => {
        if(node){
            node.focus();
        }
    }, []);

    return(
        <>
            <div ref={refChange} tabIndex="-1" aria-label="Frase" role="application" id="phrase" className='vertical-center' style={{height:'100%'}}>
                <Container className='big-font' style={{maxWidth:'100%'}}>
                    <div className='fade-in'>
                        <Row >
                            <Col>
                                {paragraphs}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="align-right">
                                {author}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='horizontal-center'>
                            <audio ref={audioRef} src={process.env.PUBLIC_URL+"/"+audio}/>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Row className="bottom-section">
                <Col className="flex-begin"> <IconButton className='svgbtn fontSize-svg' color={Yellow} function={() => fontSizeModal.current.open()} popover="Cambiar tamaño de letra" /> </Col>
                <Col className="flex-end"> <IconButton className='svgbtn next-svg' color={Yellow} function={goNext} popover="Avanzar" /> </Col>
            </Row>
            <FontSizeModal ref={fontSizeModal}/>
        </>
    );
};