import React from 'react';
import {Blue} from '../constants';
import FocusTrap  from "focus-trap-react";
import './helpModal.css';

import IconButton from './iconButton.component';

export default function HelpModal(props) {   
    return( 
        <FocusTrap active={props.show} focusTrapOptions={{ initialFocus: "#controls-modal", returnFocusOnDeactivate: false }}> 
            <div id="controls-modal" className={props.show?"show":"hide"} tabIndex="-1" role="dialog" aria-label="Ayuda." aria-describedby={props.descrBy?props.descrBy:""}>
                <div className="pop-up-panel-help">
                    <IconButton className="svgbtn pop-up-panel-help-close close-svg" color={Blue} function={() =>{ document.activeElement.blur(); props.showModal(false);}} popover='Cerrar Ayuda.'/>
                    <div tabIndex="0" className="pop-up-panel-help-content small-font">
                        {props.content}
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
}