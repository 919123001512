/* eslint-disable import/no-anonymous-default-export */
import {
    QUESTIONS_SAVED,
    ERROR
} from '../types';

export default function(state = {}, action) {
    switch (action.type) {
        case QUESTIONS_SAVED:
            return {
                ...state,
                alreadyAnswer:true,
                uuid:action.uuid,
            };
        case ERROR:
            return {
                ...state,
                alreadyAnswer:false,
                uuid:null
            };
        default:
            return state;
    }
}