import "./viewer.css";
import { Redirect } from "react-router-dom";
import React, { useRef, useState, useEffect, useMemo} from 'react';
import { Canvas, useFrame, useThree} from '@react-three/fiber';
import * as THREE from 'three';
import { Loader } from '../utils/loader.component';
import ViewerControls from './viewer-controls.component';
import ViewerHelp from './viewer-help.component';
import ViewerInfo from "./viewer-info.component";
import ViewerColorSetter from "./viewer-color-setter.component";
import { MODELS } from '../../constants.js';
import { hslToRgb } from '../color-picker/helpers/utils';
import {Environment, OrbitControls,PerspectiveCamera,Stage} from '@react-three/drei';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import ImageGallery from 'react-image-gallery';
import IconButton from '../iconButton.component';
import {Blue} from '../../constants';
import "react-image-gallery/styles/css/image-gallery.css";
import { Suspense } from "react";
import FocusTrap  from "focus-trap-react";
import { isPlatform } from '@ionic/react';
import { File } from '@ionic-native/file'
const loaderID = "loader_viewer";

function getGLTFModel(url,setModel,setProgress){
    var myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-store');

    var myInit = {
        method: 'GET',
        headers: myHeaders,
    };

    const onLoad = function ( result ) {
        setModel(result.scene);
    }

    const onError = function (error) {
        console.log(error)
    }

    if(isPlatform('capacitor') && !isPlatform('ios')){
        File.resolveLocalFilesystemUrl(url).then((fileEntry) => {
            fileEntry.file((file) => {
                var reader = new FileReader();
                reader.onprogress = function(evt) {
                    setProgress(Math.round((evt.loaded * 100) / evt.total)+"%");
                };
                reader.onloadend = function(evt) {
                    var data = evt.target.result
                    var loader = new GLTFLoader();
                    loader.setMeshoptDecoder(MeshoptDecoder);
                    loader.parse( data, '', onLoad, onError);
                };
                reader.readAsArrayBuffer(file);
            }, (err) => {
                alert("Error al cargar modelo.");
            });
        }).catch((err) => {
            alert("Error al cargar modelo. Cierra y abre la aplicación de nuevo.");
            console.log(err);
        });
    } else {
        fetch(url,myInit).then(response => response.arrayBuffer()).then( data => {
            var loader = new GLTFLoader();
            loader.setMeshoptDecoder(MeshoptDecoder);
            loader.parse( data, '', onLoad, onError);
        }).catch(rejected => {
            console.log(rejected);
        });
    }
}

/*function useFBX(url){
    const [model,setModel] = useState();


    useMemo(()=>{
        let manager = new THREE.LoadingManager();
        manager.onLoad = () => {document.getElementById(loaderID).style.display = 'none';}
        manager.onError = (err)=>{ console.log("err "+err)}
        url = process.env.PUBLIC_URL+"/models/test/Eva.fbx";
        const loader = new FBXLoader(manager);
        let index = 0;
        let childs = [];

        loader.load(
            url,
            (obj) => {
                obj.traverseVisible(
                    function ( child ) {
                        if ( child.isMesh ) {
                            child.material.castShadow=[true]
                            childs[index] = <mesh key={index} geometry={child.geometry} material={child.material} castShadow={true} receiveShadow={true}/>
                            index++
                        }
                });

                setModel(childs);
            }
        );
    },[url])

    return (
        model ? model : null
    )
}*/

function Lights(props){
    const ambientLight = useRef();
    const directLight = useRef();
    const spotLight = useRef();
    const sLights = useRef([]);
    const dLight = useRef();
    const lightsGroup = useRef();

    const showHelpers = false;

    const {scene,camera} = useThree();

    useMemo(() => {
        ambientLight.current = props.lights.hasOwnProperty('ambientLight') ? <ambientLight intensity={props.lights.ambientLight.intensity}/> : <></>;
        directLight.current = props.lights.hasOwnProperty('directionalLight') ?  props.lights.directionalLight.map((light, index) => {
            return <directionalLight
                        ref={dLight} key={index}
                        castShadow={true}
                        intensity={light.intensity}
                        position={light.position}/>
        }) : <></>
        spotLight.current = props.lights.hasOwnProperty('spotLight') ? props.lights.spotLight.map((light, index) => {
            return (
                <spotLight key={index} angle={Math.PI/3} castShadow={true}  ref={(element) => sLights.current[index] = element} intensity={light.intensity} position={light.position}/>
            )
        }) : <></>
    }, [props.lights]);

    useEffect(() => {

        if(!showHelpers){
            return;
        }

        const dH = new THREE.DirectionalLightHelper(dLight.current,20,"green");
        scene.add(dH);

        const sH = [];

        for(let i = 0; i < sLights.current.length; i++){
            if(sLights.current[i] !== null){
                sH[i] = new THREE.SpotLightHelper( sLights.current[i]);
                scene.add(sH[i]);
            }
        }

        return function cleanUp(){
            dH.dispose();
            for(let i = 0; i < sH.length; i++){
                sH[i].dispose();
            }
        }
    },[scene,showHelpers])

    useFrame(() => {
        if(lightsGroup !== null && lightsGroup.current !== null){
            lightsGroup.current.quaternion.copy(camera.quaternion)
        }
    });

    return(
        <group ref={lightsGroup} dispose={null} >
            {ambientLight.current}
            {spotLight.current}
            {directLight.current}
        </group>
    )
}

function Camera(props){
    const cam = useRef(null);
    //useHelper(cam,THREE.CameraHelper,1,'hotpink')

    return(
        <group dispose={null}>
        <PerspectiveCamera ref={cam} makeDefault={true} position={[0,100,1000]}></PerspectiveCamera>
        <Lights lights={props.lights}/>
        </group>
    )
}

function Controls(props){
    const controls = useRef();

    return(
        <OrbitControls ref={controls} minDistance={props.distance[0]} maxDistance={props.distance[1]} enablePan={true}/>
    )
}

function Model(props) {
    const model = useRef();
    const [intro,setIntro] = useState(true);
    const [anim,setAnim] = useState(true);


    //const mesh = useFBX(props.url)
    const [mesh,setMesh] = useState()

    useMemo(()=>{getGLTFModel(props.url,setMesh,props.setProgress)},[props.url])
    const {scene} = useThree();

    useEffect(() => {
        if(scene !== null){
            const rgb = hslToRgb(props.bgColor.h, props.bgColor.s, props.bgColor.l);
            scene.background = new THREE.Color(`rgb(${rgb.r},${rgb.g}, ${rgb.b})`)
        }
        //var axisHelper = new THREE.AxesHelper(100);
        //scene.add(axisHelper);
    },[scene, props, mesh]);

    function lerp (start, end, amt){
        return (1-amt)*start+amt*end
    }

    const duration = 1;

    const [time,setTime] = useState(0);
    const [t,setT] = useState(0);

    const [rotation,setRotation] = useState();
    const rot = useRef(0);

    useFrame(({camera},delta)=>{
        if(model.current && mesh && (intro || anim)){
            if(intro){
                setRotation(props.rotation[1]);
                rot.current = props.rotation[1] - Math.PI/2;
                model.current.rotation.y = rot.current;

                setTime(0);
                setIntro(false);

                if(document.getElementById(loaderID)){
                    document.getElementById(loaderID).style.display = 'none';
                }
            }

            if(intro === false && anim){
                delta = delta >= 1 ? 0 : delta
                setTime(time + delta);
                setT(time/duration);

                model.current.rotation.y = lerp(rot.current,rotation,t);

                camera.position.z = lerp(800,400,t)
                camera.position.y = lerp(100,0,t)

                if(t >= 1){
                    setAnim(false);
                    model.current.rotation.y = rotation;
                }
            }
        }
    })

    return (
        mesh ?
        <group>
            <group ref={model} {...props}>
                <primitive object={mesh}></primitive>
            </group>
            <Suspense fallback={<></>}>
                    {props.enviroment?<Environment preset={props.enviroment} />:<></>}
            </Suspense>
            {anim === false ?<Controls target={model} distance={props.distance}/>:null}
        </group>: null
    );
}

/*function ColorSetter(props){
    return(
        <div className="bottom flex-end">
            <Palet className='svgbtn flex-end' fill={Blue} onClick={() => { props.openSetter(!props.isOpen)}}/>


            {
            //<style={{backgroundColor: 'transparent', border: 'none'}}
            //<img src={colorButtonImg} alt='colorButtonImg' height="100" width="100" />
            //</Button>
            }

        </div>
    );
}*/

export default function Viewer(props) {

    const [helpOpen, toggleHelp] = useState(false);
    const [infoOpen, toggleInfo] = useState(false);
    const [colorOpen, toggleColor] = useState(false);
    const [pictureOpen, togglePicture] = useState(false);
    const [ready, setReady] = useState(false);
    const [background, setBackground] = useState({h:0, s:0, l:10.2});
    const [progress, setProgress] = useState(0);
    //const windowSize = useWindowDimensions();

    const infoTitle = useRef();
    const screenReaderText = useRef();
    const lights = useRef();
    const url = useRef();
    const position = useRef();
    const distance = useRef();
    const scale = useRef();
    const rotation = useRef();

    const [modelCanvas,setCanvas] = useState();

    const subModel = useRef();
    const subModelTitle = useRef();
    const picture = useRef();
    const enviroment = useRef();
    const images = useRef([]);
    /*useEffect(() => {
        setSize(windowSize.height);
    }, [windowSize]);*/


    useEffect(() => {
        if(props.location.state === undefined || !props.location.state.hasOwnProperty('model') || props.location.state.model === ""){
            return;
        }

        infoTitle.current = MODELS[props.location.state.model].title;
        lights.current =  MODELS[props.location.state.model].lights;
        position.current = MODELS[props.location.state.model].position;
        scale.current = MODELS[props.location.state.model].scale;

        const subModelName = MODELS[props.location.state.model].subModel;
        subModel.current = subModelName!==""?subModelName:null;
        subModelTitle.current = subModelName!==""?MODELS[subModelName].title:null;
        picture.current = MODELS[props.location.state.model].picture!=="" && MODELS[props.location.state.model].picture.length > 0;
        enviroment.current = MODELS[props.location.state.model].enviroment!==""?MODELS[props.location.state.model].enviroment:null;


        if(MODELS[props.location.state.model].picture!==""){
            for(let i = 0; i < MODELS[props.location.state.model].picture.length; ++i){
                images.current[i]={
                    original:`${require('../../resources/'+MODELS[props.location.state.model].picture[i].image).default}`,
                    originalAlt:MODELS[props.location.state.model].picture[i].alt,
                }
            }
        }

        distance.current = MODELS[props.location.state.model].distance;
        screenReaderText.current = MODELS[props.location.state.model].screenreader;

        if(isPlatform('capacitor') && !isPlatform('ios')){
            let sourcePath = File.dataDirectory;
            sourcePath = sourcePath.endsWith('/') ? sourcePath : sourcePath + "/";
            sourcePath += "models/"+props.location.state.type+"/"+props.location.state.model+'_gltfpack.glb';
            url.current = sourcePath;
        } else {
            url.current = process.env.PUBLIC_URL+'/models/'+props.location.state.type+'/'+props.location.state.model+'_gltfpack.glb';
        }

        rotation.current = MODELS[props.location.state.model].rotation.map((value)=>{return value * (Math.PI/180)});

        setReady(true);

        setCanvas(
            <Canvas id="modelCanvas" tabIndex="0" colorManagement className="viewer-main" onCreated={() => {
                document.getElementById("modelCanvas").setAttribute("aria-label", screenReaderText.current);
                document.getElementById("modelCanvas").setAttribute("role", "figure");
            }}>
                <Camera lights={lights.current}/>
                <Model position={position.current} scale={scale.current} rotation={rotation.current} distance={distance.current} url={url.current} setProgress={(prog) => setProgress(prog)} bgColor={background} enviroment={enviroment.current}/>
            </Canvas>
        )

        //setBackground({h:120, s:100, l:50});
        //setBackground({h:0, s:0, l:50.2});
    }, [props.location.state,background]);

    const changeColor = (color) => {
        setBackground(color.hsl);
    };

    const goBack = () => {
        setCanvas(<></>);
        document.getElementById(loaderID).style.display = 'flex';

        if(props.location.state.backPoint){
            props.history.go(props.location.state.backPoint * -1);
        } else {
            props.history.goBack();
        }
    };

    const openPicture = () => {
        togglePicture(!pictureOpen);
    };

    const openSubModel = () => {
        document.getElementById(loaderID).style.display = 'flex';
        setCanvas(<></>);
        const backPoint = props.location.state.backPoint;
        props.history.push('/viewer', { type: props.location.state.type, model: subModel.current, backPoint: backPoint ? backPoint + 1 : 2});
    };

    const hasParams = props.location.state !== undefined && props.location.state.hasOwnProperty('model');

    if(!hasParams || props.location.state.model === ""){
        return(<Redirect to={{pathname: "/menu"}}/>);
    }

    return (
        <div role="application" aria-label="Visualizador de modelo." className="viewer-wrapper" style={{ height: '96vh'}} >
            {ready?
            <>
                {modelCanvas}
            </> : <></>
            }
            <Loader id={loaderID} label={progress}/>

            {picture.current  === true && pictureOpen ?
            <>
                <div className="model-picture-blackgrop"></div>
                <FocusTrap focusTrapOptions={{ initialFocus: "#slide0", onActivate: () => {
                    let images = document.getElementsByClassName("image-gallery-image");
                    for(let i = 0; i < images.length; i++){
                        images[i].tabIndex = "0";
                        images[i].id = "slide"+i;
                    }
                },
                setReturnFocus: "#loader_viewer" }}>
                    <div className="model-picture-panel">
                        <IconButton tabIndex="0" className={"svgbtn close-svg image-gallery-close-btn"} color={Blue} function={openPicture} popover="Cerrar detalle"/>
                        <ImageGallery items={images.current} useWindowKeyDown={true} showThumbnails={false} showPlayButton={false} showFullscreenButton={false} useBrowserFullscreen={false}/>
                    </div>
                </FocusTrap>
            </>
            :<></>}

            <ViewerControls openHelp={toggleHelp} openInfo={toggleInfo} goBack={goBack} openPicture={picture.current?openPicture:null} openSubModel={subModel.current!=null?openSubModel:null} subModelName={subModelTitle.current} subModelIndex={props.location.state.backPoint} lightness={background.l} openSetter={toggleColor} isOpen={colorOpen}/>
            <ViewerHelp show={helpOpen} showModal={toggleHelp} />
            <ViewerInfo show={infoOpen} showModal={toggleInfo} title={infoTitle.current} modelName={props.location.state.model} />
            <ViewerColorSetter show={colorOpen} color={background} showModal={toggleColor} changeColor={changeColor}/>
        </div>
    );
}
