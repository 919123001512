import React,{useState, useEffect, Fragment} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 'calc(2.5vh + 2.5vh * var(--font-zoom))',
      lineHeight: '3vh',
  }
  }));

function IconButton(props){
    const [styles,setStyles] = useState({});

    const classes = useStyles();

    useEffect(()=>{
      let tempStyles = {};

      if(props.style){
        tempStyles = props.style;
      }

      tempStyles['background'] = props.color;

      setStyles(tempStyles);

    },[props.style, props.color])

    const handleOnClick = (event)=>{
      setTimeout(()=>{props.function()},0);
    }

    return(
      <Fragment >
        <Tooltip classes={{ tooltip: classes.tooltip }} title={props.popover}>
		      <button tabIndex="0" className={props.className} style={styles} onClick={handleOnClick} type="button"></button>
        </Tooltip>
      </Fragment>
    )
}
export default IconButton;