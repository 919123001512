export const ART_TYPES = {
    "arte-premoderno": {
        "title": "Arte Premoderno",
        "info": "Concepto de arte pre moderno en la historia artística costarricense",
        "models": ["Jadebird", "FiguraMasculina", "Virgen", "Petronila", "Venus", "Angel"],
        "style":"svgbtn-premod"
    },
    "arte-moderno":  {
        "title": "Arte Moderno",
        "info": "Concepto de arte moderno",
        "models": ["Berta", "Silla", "Armadillo", "MujerTubos", "Eva", "Leona"],
        "style":"svgbtn-moder"
    },
    "arte-contemporaneo": {
        "title": "Arte Contemporáneo",
        "info": "Concepto de arte contemporaneo",
        "models": ["DeVidrioLaCabecera", "Horus", "LaQuintaEstacion", "Simbolos", "LaFotoDeSus15", "Patriarca"],
        "style":"svgbtn-conte"
    }
};

export const MODELS = {
    "Eva": {
        "title": "Eva",
        "lights": {
            "ambientLight": { "intensity": 0.05},
            "directionalLight": [{ "intensity": 0, "position": [0,300,-100] }],
            "spotLight": [
                { "intensity":0.2, "position": [-100,120,80], "penumbra": 1 },
                { "intensity":0.4, "position": [100,120,80], "penumbra": 1 }
            ]
        },
        "position":[0,120,0],
        "scale":[35,35,35],
        "rotation":[0,0,0],
        "distance":[150,700],
        "image":"eva.png",
        "enviroment":"",
        "subModel":"EvaBusto",
        "picture":"",
        "screenreader": `
        Figura de niña de pie, vestida con vestido sencillo, pies descalzos, manos a la cintura.
        `
    },
    "Jadebird": {
        "title": "Colgante de jade",
        "lights": {
            "ambientLight": { "intensity": 0.5 },
            "directionalLight": [{ "intensity": 0.1, "position": [0,800,800] }],
            "spotLight": [
                { "intensity": 1, "position": [-180,110,100], "penumbra": 1 },
                { "intensity": 2, "position": [180,110,100], "penumbra": 1 }
            ]
        },
        "position":[0,-100,50],
        "scale":[8, 8, 8],
        "rotation":[0,0,0],
        "distance":[200,800],
        "image":"jadebird.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Colgante tallado en jadeíta, con forma hachoide. En la parte superior muestra una figura aviforme estilizada con plumaje y perforaciones a los costados. Arte Precolombino.
        `
    },
    "Leona": {
        "title": "Leona María II",
        "lights": {
            "ambientLight": { "intensity": 0.2 },
            "directionalLight": [{ "intensity": 0.4, "position": [0,200,-400] }],
            "spotLight": [
                { "intensity": 0.4, "position": [-180,100,100], "penumbra": 1 },
                { "intensity": 0.7, "position": [180,150,100], "penumbra": 1 }
            ]
        },
        "position":[0,-20,50],
        "scale":[40,40,40],
        "rotation":[0,90,0],
        "distance":[180,600],
        "image":"leona.png",
        "enviroment":"",
        "subModel":"",
        "picture":[
            {
                "image":"Photos/LeonaPhoto.jpeg",
                "alt":"Detalle de la obra Leona María II, rostro del cachorro entre las piernas de la leona, mamando."
            },
        ],
        "screenreader": `
        Escultura en madera con la figura estilizada pero naturalista de un felino autóctono de la fauna costarricense.
        `
    },
    "FiguraMasculina": {
        "title": "Escultura masculina",
        "lights": {
            "ambientLight": { "intensity": 0 },
            "directionalLight": [{ "intensity": 0.3, "position": [0,200,-50] }],
            "spotLight": [
                { "intensity": 0.125, "position": [-180,110,100], "penumbra": 1 },
                { "intensity": 0.25, "position": [180,110,100], "penumbra": 1 },
                { "intensity": 0.05, "position": [0,-50,100], "penumbra": 1 }
            ]
        },
        "position":[0,20,0],
        "scale":[45,45,45],
        "rotation":[0,0,0],
        "distance":[190,800],
        "image":"figura_masculina.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Figura masculina en pie. Un brazo levantado a la altura de la cabeza, sostiene un hacha. La otra mano estirada hacia el frente sostiene un objeto cilíndrico. Orejas horadadas, con tocado en la cabeza que llega a la mitad de la espalda.
        `
    },
    "Berta": {
        "title": "Berta (a la manera de Gargallo)",
        "lights": {
            "ambientLight": { "intensity": 0 },
            "directionalLight": [{ "intensity": 0.8, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.35, "position": [-120,0,180], "penumbra": 1 },
                { "intensity": 0.7, "position": [120,0,180], "penumbra": 1 }
            ]
        },
        "position":[0,30,-50],
        "scale":[190,190,190],
        "rotation":[0,0,0],
        "distance":[280,800],
        "image":"Berta.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
            Cabeza femenina, con rasgos estilizados; ojos almendrados, nariz alargada y fina, boca pequeña. Cortes de metal rizados a manera de cabello.
        `
    },
    "Venus": {
        "title": "Venus (desnuda)",
        "lights": {
            "ambientLight": { "intensity": 0.12 },
            "directionalLight": [{ "intensity": 0.1, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.25, "position": [-120,120,40], "penumbra": 1 },
                { "intensity": 0.5, "position": [120,120,40], "penumbra": 1 },
                { "intensity": 0.01, "position": [0,50,200], "penumbra": 1 },
            ]
        },
        "position":[0,40,0],
        "scale":[25,25,25],
        "rotation":[0,0,0],
        "distance":[125,800],
        "image":"venus.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Figura tallada en piedra, mujer de pie, desnuda, con sus brazos a los costados (no presenta mucho movimiento), con una túnica cubriendo su pelvis.
        `
    },
    "MujerTubos": {
        "title": "Mujer con tupos",
        "lights": {
            "ambientLight": { "intensity": 0.2 },
            "directionalLight": [{ "intensity": 0.5, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.2, "position": [-190,120,100], "penumbra": 1 },
                { "intensity": 0.4, "position": [190,120,100], "penumbra": 1 }
            ]
        },
        "position":[0,50,0],
        "scale":[3,3,3],
        "rotation":[0,0,0],
        "distance":[200,900],
        "image":"Mujer.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Busto de mujer recortado en dos planchas de cartón: imagen de frente y espalda. Tez morena, maquillaje, joyería (collar y aretes), cabello negro con insinuaciones de colochos por cartón doblado.
        `
    },
    "Petronila": {
        "title": "Doña Petronila González de Fonseca",
        "lights": {
            "ambientLight": { "intensity": 0.3 },
            "directionalLight": [{ "intensity": 0.05, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.25, "position": [-100,50,170], "penumbra": 1 },
                { "intensity": 0.5, "position": [100,50,170], "penumbra": 1 },
                { "intensity": 0.0, "position": [0,180,150], "penumbra": 1 }
            ]
        },
        "position":[0,60,0],
        "scale":[120,120,120],
        "rotation":[0,0,0],
        "distance":[250,800],
        "image":"petronila.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Busto de una mujer de edad avanzada, tallado en madera, policromado, de gran naturalismo. Cabello recogido en un moño, sus ojos son de vidrio y los botones de su blusa de metal.
        `
    },
    "Horus": {
        "title": "Horus",
        "lights": {
            "ambientLight": { "intensity": 0.14 },
            "directionalLight": [{ "intensity": 0.2, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.2, "position": [-80,80,100], "penumbra": 1 },
                { "intensity": 0.4, "position": [80,80,100], "penumbra": 1 }
            ]
        },
        "position":[0,30,0],
        "scale":[35,35,35],
        "rotation":[0,0,0],
        "distance":[200,800],
        "image":"horus.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Banco pequeño de madera, sobre la base la cabeza modelada de un perro. Ambas piezas presentan chorretes de pintura.
        `
    },
    "Angel": {
        "title": "Ángel de gloria",
        "lights": {
            "ambientLight": { "intensity": 0.2 },
            "directionalLight": [{ "intensity": 0.3, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.2, "position": [-100,90,100], "penumbra": 1 },
                { "intensity": 0.4, "position": [100,90,100], "penumbra": 1 }
            ]
        },
        "position":[0,90,0],
        "scale":[50,50,50],
        "rotation":[0,0,0],
        "distance":[200,800],
        "image":"Angel.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Figura masculina con ropajes amplios, corona sobre la cabeza, ambas manos extendidas, piernas descubiertas. Rostro sereno casi inexpresivo.
        `
    },
    "Armadillo": {
        "title": "Armadillo",
        "lights": {
            "ambientLight": { "intensity": 0.7 },
            "directionalLight": [{ "intensity": 0.6, "position": [0,200,-200] }],
            "spotLight": [
                { "intensity": 0.4, "position": [-180,200,100], "penumbra": 1 },
                { "intensity": 0.8, "position": [180,200,100], "penumbra": 1 },
                { "intensity": 0.3, "position": [0,-40,500], "penumbra": 1 }
            ]
        },
        "position":[0,60,0],
        "scale":[4,4,4],
        "rotation":[0,90,0],
        "distance":[200,800],
        "image":"armadillo.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Figura estilizada de un armadillo. Una sola pieza de madera semicircular abultada en la base y disminuida en la parte superior; en el centro de la figura talladas en relieve desde la base, nacen varias líneas verticales. El pedestal es una caja rectangular vertical con aberturas ovaladas en el centro de cada lado.
        `
    },
    "DeVidrioLaCabecera": {
        "title": "De vidrio la cabecera",
        "lights": {
            "ambientLight": { "intensity": 0.1 },
            "directionalLight": [{ "intensity": 0.1, "position": [0,200,100] }],
            "spotLight": [
                { "intensity": 0.2, "position": [-80,80,100], "penumbra": 1 },
                { "intensity": 0.4, "position": [80,80,100], "penumbra": 1 }
            ]
        },
        "position":[10,0,0],
        "scale":[100,100,100],
        "rotation":[10,70,0],
        "distance":[200,800],
        "image":"de_vidrio_la_cabecera.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Cama de hierro antigua de proporciones reales, almohada confeccionada en hierro fundido con encaje en los bordes.
        `
    },
    "Silla": {
        "title": "Silla (fragmento de El Combate)",
        "lights": {
            "ambientLight": { "intensity": 0.5 },
            "directionalLight": [{ "intensity": 0.5, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.4, "position": [-100,130,100], "penumbra": 1 },
                { "intensity": 0.4, "position": [100,130,100], "penumbra": 1 }
            ]
        },
        "position":[-25,-50,0],
        "scale":[200,200,200],
        "rotation":[0,0,0],
        "distance":[150,800],
        "image":"silla.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Escultura con forma de silla. Asiento y patas de proporción normal, respaldar alargado, desproporcionado. Con derrames de pinturas roja. La madera posee ondulaciones que la hacen lucir brusca.
        `
    },
    "Simbolos": {
        "title": "Símbolos patrios",
        "lights": {
            "ambientLight": { "intensity": 0.6 },
            "directionalLight": [{ "intensity": 0.5, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.6, "position": [-120,140,50], "penumbra": 1 },
                { "intensity": 1.1, "position": [1200,140,50], "penumbra": 1 },
                { "intensity": 1.1, "position": [800,140,50], "penumbra": 1 }
            ]
        },
        "position":[-45,30,0],
        "scale":[18,18,18],
        "rotation":[0,0,0],
        "distance":[75,700],
        "image":"simbolos.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Se presentan en orden de tamaño tres objetos; una réplica tallada de la imagen de la Virgen de los ángeles dentro de guarda, a su lado una botella de aguardiente "Cacique" y un balón usado de futbol.
        `
    },
    "Patriarca": {
        "title": "Patri-arca",
        "lights": {
            "ambientLight": { "intensity": 0.5 },
            "directionalLight": [{ "intensity": 0.2, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.3, "position": [-120,100,100], "penumbra": 1 },
                { "intensity": 0.6, "position": [120,100,100], "penumbra": 1 }
            ]
        },
        "position":[0,-100,0],
        "scale":[0.8,0.8,0.8],
        "rotation":[0,0,0],
        "distance":[200,800],
        "image":"patri-arca.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Estructura en madera quemada, elementos dispuestos en tres planos superpuestos. El primero muestra tres figuras antropomorfas, rodeados (atados) por cuerdas que provienen de la figura de un emblema o escudo, en el segundo plano. En el tercer plano la estructura de un triángulo enmarca toda la obra.
        `
    },
    "LaQuintaEstacion": {
        "title": "La quinta estación",
        "lights": {
            "ambientLight": { "intensity": 0.5 },
            "directionalLight": [{ "intensity": 0.6, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.3, "position": [-120,120,100], "penumbra": 1 },
                { "intensity": 0.6, "position": [120,120,100], "penumbra": 1 }
            ]
        },
        "position":[0,-80,0],
        "scale":[0.45,0.45,0.45],
        "rotation":[0,0,0],
        "distance":[150,800],
        "image":"la_quinta_estacion.png",
        "enviroment":"",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Muestra una escena con tres elementos principales; una puerta, una silla sobre un pedestal y una pequeña jaula para cazar pájaros. Hay un pequeño pájaro en cada elemento.
        `
    },
    "Virgen": {
        "title": "Virgen María",
        "lights": {
            "ambientLight": { "intensity": 0.2 },
            "directionalLight": [{ "intensity": 0.4, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.25, "position": [-100,120,100], "penumbra": 1 },
                { "intensity": 0.5, "position": [100,120,100], "penumbra": 1 }
            ]
        },
        "position":[0,60,0],
        "scale":[35,35,35],
        "rotation":[0,0,0],
        "distance":[150,800],
        "image":"virgen.png",
        "enviroment":"",
        "subModel":"",
        "picture":[
            {
                "image":"Photos/VirgenPhoto.jpeg",
                "alt":"Detalle del rostro de la obra Virgen María. Se observa el uso de cabello natural para las pestañas, los ojos de vidrio y de manera general, el gran dramatismo de los rasgos faciales."
            },
        ],
        "screenreader": `
        Figura femenina tallada, únicamente de la cintura hacia arriba, hacia abajo se añade un armazón hecho de cuatro tablillas de madera. El rostro muestra una gran tristeza, la mirada hacia arriba, en señal de adoración, ojos llorosos, las cejas casi verticales corresponde al dramatismo de los ojos, boca arqueada en símbolo de dolor. Sus manos entrelazadas a modo de oración.
        `
    },
    "LaFotoDeSus15": {
        "title": "La foto de sus 15 años",
        "lights": {
            "ambientLight": { "intensity": 0.1 },
            "directionalLight": [{ "intensity": 0.1, "position": [0,200,-100] }],
            "spotLight": [
                { "intensity": 0.15, "position": [-80,40,100], "penumbra": 1 },
                { "intensity": 0.3, "position": [80,40,100], "penumbra": 1 },
                { "intensity": 0, "position": [25,-50,80], "penumbra": 1 }
            ]
        },
        "position":[0,50,0],
        "scale":[24,24,24],
        "rotation":[0,0,0],
        "distance":[100,600],
        "image":"la_foto_de_sus_15.png",
        "enviroment":"night",
        "subModel":"",
        "picture":"",
        "screenreader": `
        Escultura con tres personajes; posiblemente familiares; madre, padre e hija, vestidos elegantemente, la niña con un vestido rosa, abultado. Los tres miran hacia el frente, parece que simulan sus expresiones para la toma de una fotografía. La obra de Leda Astorga se destaca por representaciones de personas con sobrepeso.
        `
    },
    "EvaBusto": {
        "title": "Busto de Eva",
        "lights": {
            "ambientLight": { "intensity": 0.1},
            "directionalLight": [{ "intensity": 0.5, "position": [0,300,-100] }],
            "spotLight": [
                { "intensity":1.2, "position": [-100,40,120], "penumbra": 1 },
                { "intensity":1.4, "position": [100,40,120], "penumbra": 1 }
            ]
        },
        "position":[0,50,0],
        "scale":[140,140,140],
        "rotation":[0,0,0],
        "distance":[200,700],
        "image":"eva.png",
        "enviroment":"",
        "subModel":"Eva",
        "picture":"",
        "screenreader": `
        Figura de niña de pie, vestida con vestido sencillo, pies descalzos, manos a la cintura.
        `
    },
};

export const Yellow = "#D3B037";
export const Red = "#C2151C";
export const Blue = "#2CC3EE";
export const White = "#fff"
